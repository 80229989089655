import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './ResponsesPage.module.css';

// 감정도장 이미지 임포트
import Joy from '../../Image/Emotional_stamp/stamp_JOY.png';
import SADNESS from '../../Image/Emotional_stamp/stamp_SADNESS.png';
import ANGER from '../../Image/Emotional_stamp/stamp_ANGER.png';
import DISGUST from '../../Image/Emotional_stamp/stamp_DISGUST.png';
import FEAR from '../../Image/Emotional_stamp/stamp_FEAR.png';
import ANXIETY from '../../Image/Emotional_stamp/stamp_ANXIETY.png';
import EMBARRASSMENT from '../../Image/Emotional_stamp/stamp_EMBARRASSMENT.png';
import ENNUI from '../../Image/Emotional_stamp/stamp_ENNUI.png';
import ENVY from '../../Image/Emotional_stamp/stamp_ENVY.png';

Modal.setAppElement('#root'); // 모달 접근성 설정

function ResponsesPage() {
  const userId = useSelector(state => state.auth.userId);
  const [responses, setResponses] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [selectedEmotions, setSelectedEmotions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const navigate = useNavigate();

  const emotions = [
    { id: 1, name: '기쁨', image: Joy },
    { id: 2, name: '슬픔', image: SADNESS },
    { id: 3, name: '버럭', image: ANGER },
    { id: 4, name: '까칠', image: DISGUST },
    { id: 5, name: '소심', image: FEAR },
    { id: 6, name: '불안', image: ANXIETY },
    { id: 7, name: '당황', image: EMBARRASSMENT },
    { id: 8, name: '따분', image: ENNUI },
    { id: 9, name: '부럽', image: ENVY },
  ];

  // 감정별 카운트 계산
  const emotionCounts = responses.reduce((acc, response) => {
    response.emotion_names.forEach(emotion => {
      acc[emotion] = (acc[emotion] || 0) + 1;
    });
    return acc;
  }, {});

  useEffect(() => {
    fetchAllResponses(); // 페이지 로드 시 모든 응답 불러오기
  }, [userId]);

  const fetchAllResponses = async () => {
    try {
      const result = await axios.get('/api/user/responses', {
        params: {
          emotions: emotions.map(emotion => emotion.name),
        },
      });
      setResponses(result.data);
      setFilteredResponses(result.data); // 기본적으로 모든 응답을 표시
    } catch (error) {
      console.error('Error fetching user responses:', error);
    }
  };

  const handleEmotionClick = (emotion_name) => {
    const updatedSelectedEmotions = selectedEmotions.includes(emotion_name)
      ? selectedEmotions.filter(name => name !== emotion_name)
      : [...selectedEmotions, emotion_name];
  
    setSelectedEmotions(updatedSelectedEmotions);
  
    // 로컬에서 필터링
    if (updatedSelectedEmotions.length === 0) {
      setFilteredResponses(responses);
    } else {
      setFilteredResponses(
        responses.filter(response =>
          updatedSelectedEmotions.some(selectedEmotion =>
            response.emotion_names.includes(selectedEmotion)
          )
        )
      );
    }
  };
  

  const openModal = (response) => {
    setModalContent(response);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent({});
  };

  return (
    <div className={styles.responsesPage}>
      <h1 className={styles.responsesPageTitle}>여행 기록 보기</h1>
      <div className={styles.emotionButtons}>
        {emotions.map(emotion => (
          <button
            key={emotion.id}
            className={`${styles.emotionButton} ${selectedEmotions.includes(emotion.name) ? styles.emotionButtonSelected : ''}`}
            onClick={() => handleEmotionClick(emotion.name)}
          >
            <img 
              src={emotion.image} 
              alt={emotion.name} 
              className={styles.emotionImage} 
            />
            <span className={styles.emotionName}>{emotion.name}</span>
            <span className={styles.emotionCount}>({emotionCounts[emotion.name] || 0})</span> {/* 감정 카운트 표시 */}
          </button>
        ))}
      </div>
      <div className={styles.container}>
        {/* <div className={styles.headerRow}>
          <span className={styles.headerColumn}>구분</span>
          <span className={styles.headerColumn}>질문</span>
          <span className={styles.headerColumn}>감정도장</span>
        </div> */}
        <div className={styles.responsesList}>
          {filteredResponses.length > 0 ? (
            filteredResponses.map((response) => (
              <div
                key={response.response_id}
                className={styles.responseItem}
                onClick={() => openModal(response)}  // 응답 항목 클릭 시 모달 열기
              >
                <div className={styles.responseHeader}>
                  <span className={styles.responseId}>{String(response.question_round_number || '00').padStart(2, '0')}</span>
                  <span className={styles.responseQuestion}>{response.question_text}</span>
                  <div className={styles.responseEmotions}>
                    {response.emotion_names.slice(0, 3).map((emotion, index) => {  // 최대 3개의 감정만 표시
                      const emotionObj = emotions.find(e => e.name === emotion);
                      return emotionObj ? (
                        <img
                          key={index}
                          src={emotionObj.image}
                          alt={emotionObj.name}
                          className={styles.emotionImage}
                        />
                      ) : (
                        <span key={index} className={styles.responseEmotion}>{emotion}</span>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.responseFooter}>
                  <span className={styles.responseDate}>{new Date(response.responded_at).toLocaleString()}</span>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.noResponses}>아직 응답이 없습니다.</div>
          )}
        </div>

        <button className={styles.mainButton} onClick={() => navigate('/mypage')}>
          이전으로
        </button>

      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="응답 내용"
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalHeader}>
          <span className={styles.modalRoundNumber}>{String(modalContent.question_round_number || '00').padStart(2, '0')}</span>
          <span className={styles.modalQuestionText}>{modalContent.question_text}</span>
          <div className={styles.modalEmotions}>
            {modalContent.emotion_names ? (
              modalContent.emotion_names.slice(0, 3).map(emotion => {  // 모달에서도 최대 3개 감정 표시
                const emotionObj = emotions.find(e => e.name === emotion);
                return emotionObj ? (
                  <img
                    key={emotion}
                    src={emotionObj.image}
                    alt={emotionObj.name}
                    className={styles.modalEmotionImage}
                  />
                ) : (
                  <span key={emotion} className={styles.modalEmotion}>{emotion}</span>
                );
              })
            ) : null}
          </div>
        </div>
        <div className={styles.modalContent}>
          <p>{modalContent.response_text}</p>
        </div>
        <div className={styles.modalFooter}>
          <span className={styles.modalDate}>{new Date(modalContent.responded_at).toLocaleString()}</span>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={closeModal} className={styles.closeButton}>닫기</button>
        </div>
        
      </Modal>
    </div>
  );
}

export default ResponsesPage;
