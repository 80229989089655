import React, { useState } from 'react';
import axios from 'axios';
import styles from './Findid.module.css'; // CSS 모듈 가져오기
import { useNavigate } from 'react-router-dom';

import BackButtonImage from '../../Image/Button/prev_button.svg'; // '이전으로' 버튼 이미지 경로
import VerifyButtonImage from '../../Image/Button/verify_button.svg' // 인증
import LoginButtonImage from '../../Image/Button/login_button.svg' // 로그인 버튼 이미지


const Findid = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [userId, setUserId] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setUserId('');

        if (!email || !nickname) {
            setError('이메일과 닉네임을 모두 입력해주세요.');
            return;
        }

        try {
            const response = await axios.post('/api/find-id', { // 서버 포트를 명시하세요
                email,
                nickname
            });
            if (response.data.userId) {
                setUserId(response.data.userId);
                setStep(2);
            } else {
                setError('해당하는 아이디를 찾을 수 없습니다.');
            }
        } catch (err) {
            setError(err.response?.data?.message || '오류가 발생했습니다');
        }
    };

    const handleBackToMain = () => {
        navigate('/');
    };

    const handleBackToLogin = () => {
        navigate('/login');
    };

    return (
        <div className={styles.FindidPage}>
            <div className={styles.container}>
                {step === 1 && (
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <h2 className='step1idh2'>아이디찾기</h2>
                        <p>사용자 인증을 위해 이메일과 닉네임을 입력해주세요.</p>
                        <input
                            type="email"
                            placeholder="이메일"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={styles.input}
                        />
                        <input
                            type="text"
                            placeholder="닉네임"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            className={styles.input}
                        />
                        {error && <p className={styles.error}>{error}</p>}
                        <div className={styles.buttonContainer}>
                            <button type="button" onClick={handleBackToMain} className={styles.button}>
                                <img src={BackButtonImage} alt="이전으로" className={styles.buttonImage} />
                            </button>
                            <button type="submit" className={styles.button}>
                                <img src={VerifyButtonImage} alt="인증하기" className={styles.buttonImage} />
                            </button>
                        </div>
                    </form>
                )}
                {step === 2 && (
                    <div className={styles.form}>
                        <h2>아이디 찾기</h2>
                        <p>{nickname} 님의 아이디는 {userId} 입니다.</p>
                        <button onClick={handleBackToLogin} className={styles.button}>
                            <img src={LoginButtonImage} alt="로그인하기" className={styles.buttonImage}></img>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Findid;
