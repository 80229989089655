import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "./Component.css";
import logo from "../../Image/Logo/quest21_logo.png";
import MypageIcon from '../../Image/Mypage/Mypage.svg';
import ADMIN_DASHBOARD from '../../Image/Mypage/admin_dashboard.svg';
import ADMIN_MEMBER from '../../Image/Mypage/admin_member.svg'; 


const Component = () => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const userRole = useSelector(state => state.auth.userRole);

  const myPageLink = (userRole === 'admin' || userRole === 'mentor' || userRole === 'counselor') ? '/admin-dashboard' : '/mypage';
  const Membership = userRole === 'admin' ? '/admin-dashboard-member' : null;

  return (
    <div className='main-navbar'>



      <div className='navbar-logo'>
        <Link to="/main">
          <img src={logo} alt="logo" />
        </Link>
      </div>


      {isLoggedIn && (



        <div className='navbar-mypage'>
{/* 
          {(userRole === 'admin' || userRole === 'mentor' || userRole === 'counselor') && (
            <Link to="/admin-dashboard">
              <img src={MypageIcon} alt="admin special" className='admin-button-icon' /> 
            </Link>
          )} */}

          {userRole === 'admin' && (
            <Link to={Membership}>
              <img 
                src={ADMIN_MEMBER}
                alt="admin special" 
                className='admin-button-icon' 
              />
            </Link>
          )}


          
          <Link to={myPageLink}>
            <img 
              src={(userRole === 'admin' || userRole === 'mentor' || userRole === 'counselor') ? ADMIN_DASHBOARD : MypageIcon} 
              alt="mypage" 
              className='mypage-icon' 
            />
          </Link>
        </div>



      )}
    </div>
  );
}

export default Component;
