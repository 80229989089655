import React, { useState } from 'react';
import axios from 'axios';
import styles from './Findpw.module.css'; // CSS 모듈 가져오기
import { useNavigate } from 'react-router-dom';

import BackButtonImage from '../../Image/Button/prev_button.svg'; // '이전으로' 버튼 이미지 경로
import VerifyButtonImage from '../../Image/Button/verify_button.svg'
import LoginButtonImage from '../../Image/Button/login_button.svg' // 로그인 버튼 이미지
import SettingBtn from '../../Image/Button/setting.svg' // 로그인 버튼 이미지




const FindPassword = () => {
    const [step, setStep] = useState(1);
    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [error, setError] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!userId || !email || !nickname) {
            setError('아이디, 이메일, 닉네임을 모두 입력해주세요.');
            return;
        }

        try {
            const response = await axios.post('/api/find-password', { 
                userId,
                email,
                nickname
            });
            console.log('서버 응답:', response.data);
            if (response.data.userExists) {
                setStep(2);
            } else {
                setError('해당 정보를 찾을 수 없습니다.');
            }
        } catch (err) {
            setError(err.response?.data?.message || '오류가 발생했습니다');
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setError('');

        if (!newPassword || !confirmPassword) {
            setError('새 비밀번호와 비밀번호 재확인을 모두 입력해주세요.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('새 비밀번호와 비밀번호 재확인이 일치하지 않습니다.');
            return;
        }

        try {
            const response = await axios.post('/api/reset-password', {
                userId,
                newPassword
            });
            if (response.data.success) {
                setStep(3);
            } else {
                setError('비밀번호 재설정에 실패했습니다.');
            }
        } catch (err) {
            setError(err.response?.data?.message || '오류가 발생했습니다');
        }
    };

    const handleBackToMain = () => {
        navigate('/');
    };

    const handleBackToLogin = () => {
        navigate('/login');
    };

    return (
        <div className={styles.FindPasswordPage}>
            <div className={styles.container}>
                {step === 1 && (
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <h2>비밀번호 찾기</h2>
                        <p>사용자 인증을 위해 아이디와 이메일, 닉네임을 입력해주세요.</p>
                        <input
                            type="text"
                            placeholder="아이디"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            className={styles.input}
                        />
                        <input
                            type="email"
                            placeholder="이메일"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={styles.input}
                        />
                        <input
                            type="text"
                            placeholder="닉네임"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                            className={styles.input}
                        />
                        {error && <p className={styles.error}>{error}</p>}
                        <div className={styles.buttonContainer}>
                        <button type="button" onClick={handleBackToMain} className={styles.button}>
                            <img src={BackButtonImage} alt="이전으로" className={styles.buttonImage} />
                        </button>
                        <button type="submit" className={styles.button}>
                            <img src={VerifyButtonImage} alt="인증하기" className={styles.buttonImage} />
                        </button>
                            {/* <button type="button" onClick={handleBackToMain} className={styles.button}>이전으로</button>
                            <button type="submit" className={styles.button}>인증하기</button> */}
                        </div>
                    </form>
                )}
                {step === 2 && (
                    <form className={styles.form} onSubmit={handleResetPassword}>
                    <h2>비밀번호 재설정</h2>
                    <p>새로운 비밀번호를 설정해주세요.</p>
                    <input
                        type="password"
                        placeholder="새 비밀번호"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className={styles.input}
                    />
                    <input
                        type="password"
                        placeholder="비밀번호 재확인"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className={styles.input}
                    />
                    {error && <p className={styles.error}>{error}</p>}
                    <button type="submit" className={styles.button}>
                        <img src={SettingBtn} alt="설정하기" className={styles.buttonImage}></img>
                    </button>
                </form>
                )}
                {step === 3 && (
                    <div className={styles.form}>
                        <h2>비밀번호 재설정 완료</h2>
                        <p>비밀번호 재설정이 완료되었습니다.</p>
                        <button onClick={handleBackToLogin} className={styles.button}>
                            <img src={LoginButtonImage} alt="로그인하기" className={styles.buttonImage}></img>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FindPassword;
