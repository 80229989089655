import { combineReducers } from 'redux';
import authReducer from './authReducer';

// 여기에 다른 리듀서를 추가할 수 있습니다.
const rootReducer = combineReducers({
  auth: authReducer,
  // 예시:
  // user: userReducer,
  // products: productsReducer,
});

export default rootReducer;
