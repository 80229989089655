import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import styles from './AdminDashboard.module.css';

import JOY from '../../Image/Emotional_stamp/stamp_JOY.png';
import SAD from '../../Image/Emotional_stamp/stamp_SADNESS.png';
import ANGER from '../../Image/Emotional_stamp/stamp_ANGER.png';
import DISGUST from '../../Image/Emotional_stamp/stamp_DISGUST.png';
import FEAR from '../../Image/Emotional_stamp/stamp_FEAR.png';
import ANXIETY from '../../Image/Emotional_stamp/stamp_ANXIETY.png';
import ENVY from '../../Image/Emotional_stamp/stamp_ENVY.png';
import EMBARRASSMENT from '../../Image/Emotional_stamp/stamp_EMBARRASSMENT.png';
import ENNUI from '../../Image/Emotional_stamp/stamp_ENNUI.png';

const emotionIcons = {
  '기쁨': JOY,
  '슬픔': SAD,
  '버럭': ANGER,
  '까칠': DISGUST,
  '소심': FEAR,
  '불안': ANXIETY,
  '당황': ENVY,
  '따분': EMBARRASSMENT,
  '부럽': ENNUI
};

function AdminDashboard() {
  const [questionRounds, setQuestionRounds] = useState([]);
  const [emotionFilters, setEmotionFilters] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [responses, setResponses] = useState([]);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [selectedResponseIds, setSelectedResponseIds] = useState([]);
  const [showResponse, setShowResponse] = useState(null);
  const [nonRespondingUsers, setNonRespondingUsers] = useState([]);


  const [displayRounds, setDisplayRounds] = useState([]); // 화면에 보여질 라운드 데이터를 위한 상태 0905 수정진행 

  const userRole = useSelector(state => state.auth.userRole); // userRole 가져오기


  useEffect(() => {
    console.log('Non-responding users:', nonRespondingUsers);
  }, [nonRespondingUsers]);
  

  useEffect(() => {
    fetchTotalUsers();
    fetchAllResponses();
  }, []);

  useEffect(() => {
    filterResponses();
  }, [questionRounds, emotionFilters, responses]);

////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////// 하단 코드 수정 중 

  // questionRounds가 변경될 때마다 응답하지 않은 사용자 목록을 가져오는 함수
  useEffect(() => {
    if (questionRounds.length > 0) {
      fetchNonRespondingUsers();
    } else {
      setNonRespondingUsers([]); // 선택된 회차가 없을 때 비응답 사용자 목록을 초기화합니다.
    }
  }, [questionRounds]);

  const fetchNonRespondingUsers = async () => {
    try {
      // 백엔드로 GET 요청을 보내고 응답을 받습니다.
      const response = await axios.get('/api/admin/non-responding-users', {
        params: { questionRounds: questionRounds.join(',') }
      });

      console.log("파라미터에 들어가는 값: " ,questionRounds)
      // 응답 데이터가 배열 형태인지 확인하고, 배열로 상태를 설정합니다.
      const users = Array.isArray(response.data) ? response.data : [response.data];
      
      console.log('API Response:', users);
      setNonRespondingUsers(users);
    } catch (error) {
      console.error('Error fetching non-responding users:', error);
      setNonRespondingUsers([]); // 오류 발생 시 비응답 사용자 목록을 초기화합니다.
    }
  };
  
  

  /////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  

  // user 몇 명인지 가져오기 
  const fetchTotalUsers = async () => {
    try {
      const response = await axios.get('/api/total-users');
      setTotalUsers(response.data.totalUsers);
    } catch (error) {
      console.error('Error fetching total users:', error);
    }
  };

  // 모든 user 응답 내용 가져오기 
  const fetchAllResponses = async () => {
    try {
      const response = await axios.get('/api/admin/all-responses');
      console.log('Fetched responses:', response.data);
      setResponses(response.data);
      setFilteredResponses(response.data); // 초기에는 모든 응답을 필터링된 응답으로 설정
    } catch (error) {
      console.error('Error fetching responses:', error);
    }
  };


  const filterResponses = () => {
    let filtered = [...responses];
  
    // `displayRounds` 배열에 포함된 라운드에 맞춰서 필터링
    if (displayRounds.length > 0) {
      filtered = filtered.filter(response => displayRounds.includes(response.question_round_number));
    }
  
    if (emotionFilters.length > 0) {
      filtered = filtered.filter(response => emotionFilters.some(emotion => response.emotion_names.includes(emotion)));
    }
  
    setFilteredResponses(filtered);
  };
  
  

  const handleRoundClick = (round) => {
    console.log("현재 선택한 라운드: ", round);
  
    setQuestionRounds((prevRounds) => {
      const newRounds = prevRounds.includes(round)
        ? prevRounds.filter((r) => r !== round)
        : [...prevRounds, round];
  
      // 라운드를 선택할 때 교차 데이터를 배열에 유지하여 누적
      setDisplayRounds((prevDisplayRounds) => {
        if (round === 19) {
          return prevDisplayRounds.includes(20)
            ? prevDisplayRounds.filter((r) => r !== 20)
            : [...prevDisplayRounds, 20];
        } else if (round === 20) {
          return prevDisplayRounds.includes(19)
            ? prevDisplayRounds.filter((r) => r !== 19)
            : [...prevDisplayRounds, 19];
        } else {
          return prevDisplayRounds.includes(round)
            ? prevDisplayRounds.filter((r) => r !== round)
            : [...prevDisplayRounds, round];
        }
      });
  
      console.log("업데이트된 라운드 선택: ", newRounds);
      return newRounds;
    });
  };
  
  

  const handleEmotionClick = (emotion) => {
    setEmotionFilters((prevFilters) =>
      prevFilters.includes(emotion) ? prevFilters.filter((e) => e !== emotion) : [...prevFilters, emotion]
    );
  };

  const handleExport = async () => {
    try {
      const response = await axios.post('/api/admin/export', {
        selectedResponseIds
      }, {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'responses.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error exporting responses:', error);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedResponseIds(filteredResponses.map((response) => response.response_id));
    } else {
      setSelectedResponseIds([]);
    }
  };

  const handleSelect = (responseId) => {
    setSelectedResponseIds((prevSelected) =>
      prevSelected.includes(responseId) ? prevSelected.filter((id) => id !== responseId) : [...prevSelected, responseId]
    );
  };

  const handleRowClick = (response) => {
    if (userRole === 'admin' || userRole === 'counselor') {  // 사용자의 역할이 'manager'인지 확인
      setShowResponse(response);
    } else {
      alert('권한이 없습니다.');
    }
  };

  return (
    <div className={styles.container}>
      <h2>제출 답변</h2>
      <div className={styles.panels}>
        <div className={styles.leftPanel}>
          <div className={styles.roundInfo}>
            <div className={styles.roundTitle}>{questionRounds.length > 0 ? questionRounds.join(',') : 'ALL'} 회차 응답수</div>
            <div className={styles.roundCount}>{filteredResponses.length}/{totalUsers}</div>
          </div>
          <div className={styles.roundButtons}>
            {[...Array(21).keys()].map((round) => (
              <button
                key={round}
                onClick={() => handleRoundClick(round + 1)}
                className={`${styles.roundButton} ${questionRounds.includes(round + 1) ? styles.selectedButton : ''}`}
              >
                {round + 1}
              </button>
            ))}
          </div>
          <div className={styles.notrespond}>
            {nonRespondingUsers.length > 0 && (
              <>
                <h3>응답하지 않은 사용자:</h3>
                <p>
                  {nonRespondingUsers.map((user, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && ", "}
                      {user}
                    </React.Fragment>
                  ))}
                </p>
              </>
            )}
          </div>
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.emotionTitle}>감정도장</div>
          <div className={styles.emotionButtons}>
            {['기쁨', '슬픔', '버럭', '까칠', '소심', '불안', '당황', '따분', '부럽'].map((emotion, index) => (
              <button
                key={index}
                onClick={() => handleEmotionClick(emotion)}
                className={`${styles.emotionButton} ${emotionFilters.includes(emotion) ? styles.selectedEmotionButton : ''}`}
              >
                <img src={emotionIcons[emotion]} alt={emotion} className={styles.emotionIcon} />
                {emotion}
              </button>
            ))}
          </div>
        </div>
      </div>
      {/* <div className={styles.middlePanel}>
        <button onClick={handleExport} className={styles.exportButton}>엑셀로 출력</button>
        추후 수정예정 
      </div> */}
      <div className={styles.bottomPanel}>
        <table className={styles.responseTable}>
          <thead>
            <tr>
              <th><input type="checkbox" onChange={handleSelectAll} /></th>
              <th>구분</th>
              <th>닉네임</th>
              <th>퀘스트</th>
              <th>감정도장</th>
              <th>기록일시</th>
            </tr>
          </thead>
          <tbody>
            {filteredResponses.map((response, index) => (
              <tr key={response.response_id} onClick={() => handleRowClick(response)}>
                <td><input type="checkbox" checked={selectedResponseIds.includes(response.response_id)} onChange={() => handleSelect(response.response_id)} /></td>
                <td>{index + 1}</td>
                <td>{response.user_nickname}</td>
                <td className={styles.truncate}>{response.question_text}</td>
                <td className={styles.responseEmotions}>
                  {response.emotion_names.map((emotion, i) => (
                    <img key={i} src={emotionIcons[emotion]} alt={emotion} className={styles.emotionIconSmall} />
                  ))}
                </td>
                <td>{new Date(response.responded_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showResponse && (
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <button className={styles.closeButton} onClick={() => setShowResponse(null)}>×</button>
            <h3>응답 상세</h3>
            <p><strong>닉네임:</strong> {showResponse.user_nickname}</p>
            <p><strong>질문:</strong> {showResponse.question_text}</p>
            <p><strong>응답 내용:</strong> {showResponse.response_text}</p>
            <p><strong>기록 일시:</strong> {new Date(showResponse.responded_at).toLocaleString()}</p>
            <div className={styles.responseEmotions}>
              {showResponse.emotion_names.map((emotion, i) => (
                <div key={i}>
                  <img src={emotionIcons[emotion]} alt={emotion} className={styles.emotionIconSmall} />
                  <span>{emotion}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
