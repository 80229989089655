import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { checkAuth } from '../../redux/actions/authActions'; // 인증 액션 임포트
import styles from './Main.module.css';

// 버튼 임포트
import LoginButtonImage from '../../Image/Button/login_button.svg'; // 로그인 버튼 이미지

// 21개의 이미지 파일 import
// import island1 from '../../Image/Emblem/island1.png';
// import island2 from '../../Image/Emblem/island2.png';
// import island3 from '../../Image/Emblem/island3.png';
// import island4 from '../../Image/Emblem/island4.png';
// import island5 from '../../Image/Emblem/island5.png';
// import island6 from '../../Image/Emblem/island6.png';
// import island7 from '../../Image/Emblem/island7.png';
// import island8 from '../../Image/Emblem/island8.png';
// import island9 from '../../Image/Emblem/island9.png';
// import island10 from '../../Image/Emblem/island10.png';
// import island11 from '../../Image/Emblem/island11.png';
// import island12 from '../../Image/Emblem/island12.png';
// import island13 from '../../Image/Emblem/island13.png';
// import island14 from '../../Image/Emblem/island14.png';
// import island15 from '../../Image/Emblem/island15.png';
// import island16 from '../../Image/Emblem/island16.png';
// import island17 from '../../Image/Emblem/island17.png';
// import island18 from '../../Image/Emblem/island18.png';
// import island19 from '../../Image/Emblem/island19.png';
// import island20 from '../../Image/Emblem/island20.png';
// import island21 from '../../Image/Emblem/island21.png';

import backgroundImage from '../../Image/background/background.png';
import readyImage from '../../Image/Button/ready_button.svg'; // "출발하기!" 상태 이미지
import waitingImage from '../../Image/Button/wait_button.svg'; // "대기중" 상태 이미지
import LogoutImage from '../../Image/Button/logout_button.svg'; // 로그아웃 이미지


////////////////////////////////////
//// 스테이지 기능

import { getRoundMessage } from '../Component/RoundMessages';



/// 이미지

import { getImageByRound } from '../Component/Images';

const Main = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState({});
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [unansweredQuestion, setUnansweredQuestion] = useState(null);
  // const [islandImageSrc, setIslandImageSrc] = useState(island1);
  const [islandImageSrc, setIslandImageSrc] = useState(getImageByRound(0)); // 기본값으로 첫 번째 이미지를 설정
  const [activeQuestion, setActiveQuestion] = useState(null);
  const userRole = useSelector((state) => state.auth.userRole); // 사용자 역할 가져오기
  

  // const images = [
  //   island1, island2, island3, island4, island5, island6, island7, island8, island9, island10,
  //   island11,island12,island13,island14,island15,island16,island17,island18,island19,island20,island21
  // ];


  const calculateTimeLeft = () => {
    const now = new Date(); // 현재 시간을 그대로 사용 (서울 시간으로 이미 설정된 경우)
    // console.log("지금 시간 (서울 시간)", now);
  
    const nextNinePm = new Date(now);
    nextNinePm.setHours(21, 0, 0, 0); // 21시 (9PM)로 설정
  
    if (now.getHours() >= 21) {
      nextNinePm.setDate(nextNinePm.getDate() + 1); // 현재 시간이 21시 이후라면 다음 날 21시로 설정
    }
  
    const difference = nextNinePm - now;
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
  
    return timeLeft;
  };
  

  
  
  

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft();
      setTimeLeft(updatedTimeLeft);

      if (updatedTimeLeft.hours === 0 && updatedTimeLeft.minutes === 0 && updatedTimeLeft.seconds === 0) {
        setIsTimeUp(true);
        fetchActiveQuestion(); // 21시가 되었을 때 활성화된 질문을 다시 가져옴
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const fetchActiveQuestion = async () => {
    try {
      const response = await axios.get('/api/questions/active');
      const activeQuestion = response.data;
      if (activeQuestion) {
        setActiveQuestion(activeQuestion);
        setIslandImageSrc(getImageByRound(activeQuestion.question_round_number)); // 라운드 번호에 따른 이미지 설정
        console.log("답변 데이터 확인", response.data)
        console.log("activeQuestion 라운드 넘버 확인 ", activeQuestion.question_round_number)
        console.log("activeQuestion 라운드 넘버 확인 +1 ", activeQuestion.question_round_number+1)
      }
    } catch (error) {
      console.error('Error fetching active question:', error);
    }
  };

  useEffect(() => {
    dispatch(checkAuth()); // 컴포넌트가 로드될 때 인증 상태 확인

    // 활성화된 응답하지 않은 질문 확인
    axios.get('/api/questions/unanswered-active')
      .then(response => {
        if (response.data) {
          setUnansweredQuestion(response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching unanswered active question:', error);
      });

    // 활성화된 질문 정보 가져오기
    fetchActiveQuestion();
  }, [dispatch]);

  const handleLogout = () => {
    axios.post('/api/logout')
      .then(() => {
        dispatch({ type: 'LOGOUT' });
        window.location.reload(); // 페이지 새로고침
        navigate('/');
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  const handleResponseNavigation = async () => {
    if (isTimeUp) { // 출발하기일 때만 실행
      try {
        await fetchActiveQuestion(); // /api/questions/active 요청을 보내서 최신 질문을 가져옴
        if (activeQuestion) {
          console.log('Navigating to question:', activeQuestion);
          navigate(`/answer/${activeQuestion.question_id}`);
        } else {
          console.error('No active question found.');
        }
      } catch (error) {
        console.error('Error navigating to question:', error);
      }
    }
  };
  

  return (
    <div className={styles.mainPage} style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
      {userRole === 'user' && unansweredQuestion && (
        // userRole이 'user'인 경우에만 알림 메시지 표시
        <div className={styles.notification}>
          <p>아직 제출하지 못한 질문이 있어요!</p>
        </div>
      )}
      {isLoggedIn ? (
        <div className={styles.loggedInContent}>
          
          {/* <div className={styles.loginText}>다음 여행지까지 남은 시간은...</div> */}
          <div className={styles.loginText}>
            {getRoundMessage(activeQuestion ? activeQuestion.question_round_number : null)}까지 남은 시간은...
          </div>
          <img src={islandImageSrc} alt="Center Image" className={styles.centerImage} />

          
          <div className={styles.countdownTimer}>
            {timeLeft.hours !== undefined ? (
              <>
                {String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:{String(timeLeft.seconds).padStart(2, '0')}
              </>
            ) : (
              '00:00:00'
            )}
          </div>
          <div className={styles.buttonlogged}>
          {userRole !== 'admin' && userRole !== 'mentor' && userRole !== 'counselor' && ( 
                // 관리자, 멘토, 상담사가 아닌 경우에만 "대기중..." 또는 "출발하기!" 버튼 표시
              <button
                className={styles.actionButton}
                onClick={handleResponseNavigation}
                disabled={!isTimeUp} // isTimeUp이 false면 버튼이 비활성화됨
                style={{ backgroundColor: 'transparent' }} // 배경 이미지를 제거
              >
                <img 
                  src={isTimeUp ? readyImage : waitingImage} 
                  alt={isTimeUp ? '출발하기!' : '대기중...'} 
                  className={styles.statusImage} 
                />
              </button>
            )}
            {userRole !== 'user' && ( // 사용자가 아닌 경우에만 "로그아웃" 버튼 표시
              <button onClick={handleLogout} className={styles.authButton}>
                <img src={LogoutImage} alt="로그아웃 버튼" className={styles.buttonImage} />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.loggedOutContent}>
          <div className={styles.loginText}>
            {getRoundMessage(activeQuestion ? activeQuestion.question_round_number : null)}까지 남은 시간은...
          </div>
          <img src={islandImageSrc} alt="Center Image" className={styles.centerImage} />
          <div className={styles.countdownTimer}>
            {timeLeft.hours !== undefined ? (
              <>
                {String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:{String(timeLeft.seconds).padStart(2, '0')}
              </>
            ) : (
              '00:00:00'
            )}
          </div>
          <div className={styles.authContainer}>
            {/* <button onClick={() => navigate('/login')} className={styles.loginButton}>로그인하기</button> */}
            <button onClick={() => navigate('/login')} className={styles.loginButton}>
                <img src={LoginButtonImage} alt="로그인하기" className={styles.buttonImage}></img>
            </button>
            <div className={styles.authLinks}>
              <button onClick={() => navigate('/findid')} className={styles.authLink}>아이디 찾기</button>
              <button onClick={() => navigate('/findpw')} className={styles.authLink}>비밀번호 찾기</button>
              <button onClick={() => navigate('/register')} className={styles.authLink}>회원가입하기</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
