// src/components/AdminRoute.js


///// 해당 컴포넌트는 "관리자 회원 정보"에 접근 권한에 따른 내용입니다.
///// 관리자 회원 정보 내용은 사용자들의 아이디, 이메일, 사용자 구분, 최근 접속 일시, 팀 내용을 확인할 수 있습니다.
///// 특히, 새로 가입된 사용자를 승인할 수 있습니다

import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const AdminRoute = ({ element: Component, ...rest }) => {
  const { isLoggedIn, userRole, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // 인증 상태를 확인 중일 때 표시되는 메시지
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />; // 로그인되어 있지 않으면 로그인 페이지로 리디렉션
  }

  if (userRole !== 'admin') {
    return <div>접근 권한이 없습니다</div>; // 관리자가 아니면 접근 권한이 없다는 메시지 표시
    // 현재 관리자 회원 정보 페이지는 "admin"만 접근 가능
  }

  return <Component {...rest} />; // 로그인되어 있고 관리자라면 해당 컴포넌트를 렌더링
};

export default AdminRoute;
