const initialState = {
  isLoggedIn: false,
  userId: null,
  userRole: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoggedIn: true,
        userId: action.payload.userId,
        userRole: action.payload.userRole,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        isLoggedIn: false,
        userId: null,
        userRole: null,
      };
    default:
      return state;
  }
};

export default authReducer;
