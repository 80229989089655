// src/components/AdminRoute.js


///// 해당 컴포넌트는 "관리자 대시보드"에 접근 권한에 따른 내용입니다.
///// 관리자 대시보는 사용자의 응답을 확인할 수 있는 대시보드입니다.



import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const AdminRoute = ({ element: Component, ...rest }) => {
  const { isLoggedIn, userRole, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; // 인증 상태를 확인 중일 때 표시되는 메시지
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />; // 로그인되어 있지 않으면 로그인 페이지로 리디렉션
  }

  if (userRole !== 'admin' && userRole !== 'mentor' && userRole !== 'counselor') {
    return <div>접근 권한이 없습니다</div>; // 관리자가 아니면 접근 권한이 없다는 메시지 표시
    // 현재 관리자, 멘토, 상담사만 접근 가능함.
  }

  return <Component {...rest} />; // 로그인되어 있고 관리자라면 해당 컴포넌트를 렌더링
};

export default AdminRoute;
