import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './Mypage.module.css'; // 스타일 모듈을 가져옵니다.

// 필요한 이미지를 미리 불러옵니다.
import island1 from '../../Image/Emblem/island1.png';
import island2 from '../../Image/Emblem/island2.png';
import island3 from '../../Image/Emblem/island3.png';
import island4 from '../../Image/Emblem/island4.png';
import island5 from '../../Image/Emblem/island5.png';
import island6 from '../../Image/Emblem/island6.png';
import island7 from '../../Image/Emblem/island7.png';
import island8 from '../../Image/Emblem/island8.png';
import island9 from '../../Image/Emblem/island9.png';
import island10 from '../../Image/Emblem/island10.png';
import island11 from '../../Image/Emblem/island11.png';
import island12 from '../../Image/Emblem/island12.png';
import island13 from '../../Image/Emblem/island13.png';
import island14 from '../../Image/Emblem/island14.png';
import island15 from '../../Image/Emblem/island15.png';
import island16 from '../../Image/Emblem/island16.png';
import island17 from '../../Image/Emblem/island17.png';
import island18 from '../../Image/Emblem/island18.png';
import island19 from '../../Image/Emblem/island19.png';
import island20 from '../../Image/Emblem/island20.png';
import island21 from '../../Image/Emblem/island21.png';

// 새로 추가한 이미지 경로
// import companyLogo1 from '../../Image/Logo/logo1.png';
// import companyLogo2 from '../../Image/Logo/logo2.png';

import COIN from '../../Image/Mypage/COIN.svg'
import MY from '../../Image/Mypage/MY.svg'
import TEAM from '../../Image/Mypage/TEAM.svg'
import { subtle } from 'crypto';

const images = {
  1: island1,
  2: island2,
  3: island3,
  4: island4,
  5: island5,
  6: island6,
  7: island7,
  8: island8,
  9: island9,
  10: island10,
  11: island11,
  12: island12,
  13: island13,
  14: island14,
  15: island15,
  16: island16,
  17: island17,
  18: island18,
  19: island19,
  20: island20,
  21: island21,
};

const TicketCard = ({ question, onClick, isMissed }) => {
  const formattedDate = new Date(question.question_created_at).toLocaleDateString();
  const imageUrl = images[question.question_round_number];

  return (
    <div className={styles.ticketCard} onClick={onClick}>

      <div className={styles.ticketCardLeftSection}>
        
        <div className={styles.ticketCardFlightInfo}>
          <span>FLIGHT QUEST {question.question_round_number}</span>
        </div>
        
        <div className={styles.ticketCardDateInfo}>
          <span>DATE {formattedDate}</span>
        </div>

        <p className={styles.questiontexttext}>{question.question_text}</p>

        <div className={styles.barcode}></div>
        </div>

        {/* <div className={styles.ticketCardDateInfo}>
          <span>DATE {formattedDate}</span>
        </div> */}

      <div className={styles.ticketCardRightSection} style={{ backgroundColor: isMissed ? '#79797979' : '#E0E0E0' }} // 여기서 배경색을 조건부로 적용합니다.
      >
        {imageUrl && <img src={imageUrl} alt="Trip" />}
      </div>
    </div>
  );
};

// 간단한 reducer 함수 추가
const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return { ...state, isLoggedOut: true };
    default:
      return state;
  }
};

function MyPage() {
  const [userData, setUserData] = useState(null);
  const [emotionsCount, setEmotionsCount] = useState([
    { emotion_name: '기쁨', count: 0 },
    { emotion_name: '슬픔', count: 0 },
    { emotion_name: '버럭', count: 0 },
    { emotion_name: '까칠', count: 0 },
    { emotion_name: '소심', count: 0 },
    { emotion_name: '불안', count: 0 },
    { emotion_name: '당황', count: 0 },
    { emotion_name: '따분', count: 0 },
    { emotion_name: '부럽', count: 0 },
  ]);
  const [recentQuestions, setRecentQuestions] = useState([]);
  const [missedQuestions, setMissedQuestions] = useState([]);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, { isLoggedOut: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const userDataResponse = await fetch('/api/user-data');
        const userData = await userDataResponse.json();
        setUserData(userData);

        // Fetch emotions count
        const emotionsCountResponse = await fetch('/api/emotions-count');
        const emotionsCountData = await emotionsCountResponse.json();
        const updatedEmotionsCount = [...emotionsCount];
        emotionsCountData.forEach(emotion => {
          const index = updatedEmotionsCount.findIndex(e => e.emotion_name === emotion.emotion_name);
          if (index !== -1) {
            updatedEmotionsCount[index].count = emotion.count;
          }
        });
        setEmotionsCount(updatedEmotionsCount);

        // Fetch recent questions
        const recentQuestionsResponse = await fetch('/api/recent-questions');
        const recentQuestionsData = await recentQuestionsResponse.json();
        setRecentQuestions(recentQuestionsData);

        // Fetch missed questions
        const missedQuestionsResponse = await fetch('/api/missed-questions');
        const missedQuestionsData = await missedQuestionsResponse.json();
        setMissedQuestions(missedQuestionsData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    axios.post('/api/logout')
      .then(() => {
        dispatch({ type: 'LOGOUT' });
        navigate('/');
        navigate('/');
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.MyPage}>
      <div className={styles.mypageContainer}>
        
          <div className={styles.hello}>
            <h2 className={styles.hellonncickname}>반가워요! 퀘스터 {userData.user_nickname}님</h2>
          </div>


          <div className={styles.leftPanel}>
            <p>퀘스터</p>
            <h2 className={styles.usernickname}>{userData.user_nickname}</h2>
            <p className={styles.teamanmemypage}>{userData.team_name ? userData.team_name : '팀이 아직 지정되지 않았습니다.'}</p>

            <div className={styles.records} onClick={() => navigate('/responses')}>
                <button className={styles.responsesrecords}>여행기록보기</button>
            </div>

            <div className={styles.pointrank}>

              <div className={styles.statpoint} onClick={() => navigate('/pointhistory')}>
                <span className={styles.pointLabel}>포인트</span>

                  <img src={COIN} alt="point icon" className={styles.pointicon}/>
                  <span className={styles.pointValue}>{userData.user_point}</span>
              </div>

              <div>
                <hr style={{ border: "1px solid white" }} />
              </div>


              <div className={styles.individual} onClick={() => navigate('/Leaderboard')}>
                <span className={styles.rankLabelindividual}>개인 랭킹</span>
                <img src={MY} alt="rank icon" />
                <span className={styles.personallabel}>{userData.personal_rank}</span>
              </div>

              <div>
                <hr style={{ border: "1px solid white" }} />
              </div>


              <div className={styles.teamran} onClick={() => navigate('/Leaderboard')}>
                <span className={styles.teamLeaderboard}>팀 랭킹</span>
                <img src={TEAM} alt="rank icon" className={styles.teamranImg} />
                <span className={styles.teamrank}>{userData.team_rank}</span>
              </div>

            </div>

              <div className={styles.userActions}>
                <button onClick={handleLogout}>로그아웃</button>
              </div>
          </div>

          <div className={styles.TravelPanel}>
            <div className={styles.recentTrips}>
              <h2>최근 여행지</h2>
              {recentQuestions.slice(0, 1).map(question => (
                <TicketCard key={question.question_id} question={question} />
              ))}
            </div>
            <div className={styles.unansweredTrips}>
              <h2>놓친 여행지</h2>
              {missedQuestions.map(question => (
                <TicketCard 
                  key={question.question_id} 
                  question={question}
                  onClick={() => navigate(`/answer/${question.question_id}`)} 
                  isMissed={true} // 놓친 여행지에는 isMissed를 true로 전달합니다. 
                />
              ))}
            </div>
          </div>
        
      </div>
    </div>
  );
}

export default MyPage;
