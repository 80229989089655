// roundMessages.js
export const roundMessages = [
    "솔직함의 강가",
    "평온의 숲",
    "나른한 해변",
    "즐거움의 언덕",
    "무지개 협곡",
    "회고의 사막",
    "확신의 산성",
    "다짐의 계곡",
    "끈기의 빙하",
    "기대의 봉우리",
    "고요의 동굴",
    "자신감의 절벽",
    "모험의 정글",
    "열정의 화산",
    "희망의 등대",
    "해방의 고원",
    "설렘의 정원",
    "안도의 오아시스",
    "혼란의 파도",
    "솔직함의 호수",
    "시작과 끝의 항구",
];

export const getRoundMessage = (roundNumber) => {
    return roundMessages[roundNumber] || "다음 여행지";
  };