import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './Pointhistory.module.css';

import COIN from '../../Image/Mypage/COIN.svg'

function App() {
  const [totalPoints, setTotalPoints] = useState(0);
  const [pointHistory, setPointHistory] = useState([]);

  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const response = await axios.get('/api/point-history');
        setTotalPoints(response.data.totalPoints);
        setPointHistory(response.data.pointHistory);
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    };

    fetchPoints();
  }, []);

  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        <h1>포인트 내역</h1>
        <div className={styles.TotalPoints}>
            <div className={styles.TotalPointsLabel}>전체 포인트</div>
            <div className={styles.TotalPointsValueContainer}>
              <img 
                src={COIN} 
                alt="Coin" 
                className={styles.CoinImage} 
              />
              <div className={styles.TotalPointsValue}>
                {totalPoints}
              </div>
            </div>
        </div>
        <div className={styles.PointHistory}>
          {/* <div className={styles.PointHistoryHeader}>
            <div className={styles.HeaderItem}>구분</div>
            <div className={styles.HeaderItem}>포인트 항목</div>
            <div className={styles.HeaderItem}>적립 포인트</div>
          </div> */}
          {pointHistory.length > 0 ? (
            pointHistory.map((point, index) => (
              <div key={index} className={styles.PointEntry}>
                <div className={styles.PointNumber}>{index + 1}</div>
                <div className={styles.PointMain}>
                  <div className={styles.PointDetails}>
                    <div className={styles.PointDescription}>{point.point_description}</div>
                    <div className={styles.PointCreatedAt}>
                      <span className={styles.DatePart}>
                        {new Date(point.point_created_at).toLocaleDateString('ko-KR', { month: '2-digit', day: '2-digit' })}
                      </span>
                      <span className={styles.TimePart}>
                        {new Date(point.point_created_at).toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                      </span>
                    </div>
                  </div>
                  <div className={styles.PointProvision}>+ {point.point_provision}</div>
                </div>
              </div>
            ))
          ) : (
            <div>포인트 내역이 없습니다.</div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
