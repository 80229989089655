import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './AdminPointManagement.module.css';

const AdminPointManagement = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pointProvision, setPointProvision] = useState('');
  const [pointDescription, setPointDescription] = useState('');

  useEffect(() => {
    fetchUsers();
  }, [currentPage]);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`/api/admin/point/users?page=${currentPage}`);
      
      if (response.data && Array.isArray(response.data.users)) {
        setUsers(response.data.users);
        setTotalPages(response.data.totalPages || 1);
      } else {
        throw new Error('Users data is not an array or no data received');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError(`사용자 데이터를 불러오는 데 실패했습니다: ${error.message}`);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handlePointProvision = async () => {
    try {
      await axios.post('/api/admin/provide-points', {
        userId: selectedUser.user_id,
        points: parseInt(pointProvision, 10),
        description: pointDescription
      });
      alert('포인트가 성공적으로 지급되었습니다.');
      setSelectedUser(null);
      setPointProvision('');
      setPointDescription('');
      fetchUsers(); // 포인트 지급 후 사용자 목록을 다시 불러옴
    } catch (error) {
      console.error('Error providing points:', error);
      setError(`포인트 지급 중 오류가 발생했습니다: ${error.message}`);
    }
  };

  const closeModal = () => {
    setSelectedUser(null);
  };

  const isSubmitDisabled = !pointProvision || !pointDescription;

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.adminPointManagement}>
      <h1 className={styles.title}>사용자 포인트 관리</h1>
      <p className={styles.description}>총 사용자 수: {users.length}</p>
      {users.length > 0 ? (
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th}>회원아이디</th>
              <th className={styles.th}>아이디</th>
              <th className={styles.th}>사용자 구분</th>
              <th className={styles.th}>관리</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.user_id}>
                <td className={styles.td}>{String(index + 1).padStart(2, '0')}</td>
                <td className={styles.td}>{user.user_nickname}</td>
                <td className={styles.td}>{user.user_role === 'admin' ? '관리자' : '사용자'}</td>
                <td className={styles.td}>
                  <button
                    className={styles.manageButton}
                    onClick={() => handleUserClick(user)}
                  >
                    포인트 지급
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className={styles.noData}>사용자 데이터가 없습니다.</p>
      )}
      
      <div className={styles.pagination}>
        <button 
          className={styles.paginationButton} 
          onClick={handlePreviousPage} 
          disabled={currentPage === 1}
        >
          이전
        </button>
        <span>{currentPage} / {totalPages}</span>
        <button 
          className={styles.paginationButton} 
          onClick={handleNextPage} 
          disabled={currentPage === totalPages}
        >
          다음
        </button>
      </div>

      {selectedUser && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={closeModal}>X</button>
            <h2>포인트 지급</h2>
            <p>사용자 ID: {selectedUser.user_id}</p>
            <p>닉네임: {selectedUser.user_nickname}</p>
            <p>현재 포인트: {selectedUser.user_point}</p>
            <input 
              className={styles.input}
              type="number" 
              value={pointProvision} 
              onChange={(e) => setPointProvision(e.target.value)}
              placeholder="지급할 포인트"
            />
            <input 
              className={styles.input}
              type="text" 
              value={pointDescription} 
              onChange={(e) => setPointDescription(e.target.value)}
              placeholder="포인트 지급 설명"
            />
            <div className={styles.buttonGroup}>
              <button 
                className={styles.submitButton} 
                onClick={handlePointProvision} 
                disabled={isSubmitDisabled} // 지급할 포인트와 설명이 없으면 버튼 비활성화
              >
                지급
              </button>
              <button className={styles.cancelButton} onClick={closeModal}>취소</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPointManagement;
