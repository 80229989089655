import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './redux/store';
import { checkAuth } from './redux/actions/authActions';

import NavBar from './pages/Component/Component';
import Home from './pages/Home/Home';
import Register from './pages/Register/Register';
import Login from './pages/Login/Login';
import Main from './pages/Main_page/Main';
import ResponsesPage from './pages/ResponsesPage/ResponsesPage';

import TEST from './pages/Question_page/QuestionPage';
import Leaderboard from './pages/Leaderboard/Leaderboard';
import Mypage from './pages/Mypage/Mypage';
import FINDID from './pages/FindID/Findid';
import FINDPW from './pages/FindPW/Findpw';

import POINTHISTORY from './pages/Pointhistory/Pointhistory'; // 포인트 내역 페이지

import UserEdit from './pages/Edituser/UserEdit'; // 사용자 회원 정보 수정

import MYQuestionPageMY from './pages/My_QuestionPage/QuestionPage' // 수정 중 마이페이지 -> 질문  



////// 관리자 관련 페이지

/// 1. 관리자 권한 확인을 위한 컴포넌트

import AdminRoute from './components/AdminRoute'; // 관리자 대시보드 (응답 확인 등)

import AdminMemberPage from './components/Admin_Member_Route'; // 관리자 회원 정보 관리 페이지 


// 관리자 페이지
import AdminMembershipManage from './pages/Adminmembershipmanage/Membershipmanage'; // 
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';  // 응답 확인 페이지 

import Adminpointpage from './pages/Admin_Point/Admin_point'; // 관리자 포인트 지급 페이지


import ADMINPOINT from './pages/Admin_member/admin_pointMembershipmanage'; /// 현재 수정 중 (포인트 관련 페이지)

//////// 관리자 관련 페이지 끝. 





// 마이페이지 다시 생성

import Mypagetest2 from './pages/Mypagetest2/TEST_MY';


// 마이페이지 2번째

import DATA22 from './pages/Backup/backup';


import './App.css';

function AppContent() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Main />} />
        <Route path="/main" element={<Main />} />
        <Route path="/responses" element={<ResponsesPage />} />
        <Route path="/test" element={<TEST />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/mypage" element={<Mypage />} />
        <Route path="/admin-dashboard" element={<AdminRoute element={AdminDashboard} />} />
        <Route path="/admin-dashboard-member" element={<AdminMemberPage element={AdminMembershipManage} />} />
        <Route path="/adminpoint" element={<ADMINPOINT/>} />
        <Route path="admin-dashboard-point" element={<AdminMemberPage element={Adminpointpage} />} />

        <Route path="/answer/:id" element={<MYQuestionPageMY />} />
        <Route path="/mypagegggg" element={<Mypagetest2 />} />
        <Route path="/backup" element={<DATA22 />} />

        <Route path="/findid" element={<FINDID />} />
        <Route path="/findpw" element={<FINDPW />} />
        <Route path="/pointhistory" element={<POINTHISTORY />} />
        <Route path="/useredit" element={<UserEdit />} />

      </Routes>
    </Router>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppContent />
      </PersistGate>
    </Provider>
  );
}

export default App;
