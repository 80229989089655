import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './UserEdit.module.css';

function UserEdit() {
  const [step, setStep] = useState(1);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userInfo, setUserInfo] = useState({
    user_login_id: '',
    new_password: '',
    confirm_password: '',
    user_email: '',
    user_nickname: ''
  });
  const navigate = useNavigate();

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/verify-password', { password });
      if (response.data.success) {
        setStep(2);
        setUserInfo(response.data.user);
      } else {
        setErrorMessage('비밀번호가 올바르지 않습니다.');
      }
    } catch (error) {
      setErrorMessage('서버 오류가 발생했습니다. 다시 시도해 주세요.');
    }
  };

  const handleBackClick = () => {
    navigate('/login');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (userInfo.new_password !== userInfo.confirm_password) {
      setErrorMessage('새로운 비밀번호와 비밀번호 확인이 일치하지 않습니다.');
      return;
    }
    try {
      const response = await axios.post('/api/update-user', userInfo);
      if (response.data.success) {
        alert('사용자 정보가 성공적으로 수정되었습니다.');
        navigate('/profile'); // 예를 들어 프로필 페이지로 이동
      } else {
        setErrorMessage('사용자 정보 수정에 실패했습니다.');
      }
    } catch (error) {
      setErrorMessage('서버 오류가 발생했습니다. 다시 시도해 주세요.');
    }
  };

  const renderStep1 = () => (
    <div className={styles.container}>
      <h2>사용자 인증을 위해 비밀번호를 입력해주세요.</h2>
      <form onSubmit={handlePasswordSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="비밀번호"
          className={styles.input}
        />
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        <div className={styles.buttonContainer}>
          <button type="button" onClick={handleBackClick} className={styles.submitButton}>이전으로</button>
          <button type="submit" className={styles.submitButton}>인증하기</button>
        </div>
      </form>
    </div>
  );

  const renderStep2 = () => (
    <div className={styles.container}>
      <h2>사용자 정보를 수정하세요.</h2>
      <form onSubmit={handleUpdateSubmit}>
        <div className={styles.formGroup}>
          <label>아이디</label>
          <span>{userInfo.user_login_id}</span>
        </div>
        <div className={styles.formGroup}>
          <label>새로운 비밀번호</label>
          <input
            type="password"
            name="new_password"
            value={userInfo.new_password}
            onChange={handleInputChange}
            placeholder="새로운 비밀번호"
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label>비밀번호 재확인</label>
          <input
            type="password"
            name="confirm_password"
            value={userInfo.confirm_password}
            onChange={handleInputChange}
            placeholder="비밀번호 재확인"
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label>이메일</label>
          <input
            type="email"
            name="user_email"
            value={userInfo.user_email}
            onChange={handleInputChange}
            placeholder="이메일"
            className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label>닉네임</label>
          <input
            type="text"
            name="user_nickname"
            value={userInfo.user_nickname}
            onChange={handleInputChange}
            placeholder="닉네임"
            className={styles.input}
          />
        </div>
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        <button type="submit" className={styles.submitButton}>수정하기</button>
      </form>
    </div>
  );

  return (
    <div>
      {step === 1 ? renderStep1() : renderStep2()}
    </div>
  );
}

export default UserEdit;
