import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pointDetails, setPointDetails] = useState({
    userId: "",
    points: 0,
    reason: ""
  });

  useEffect(() => {
    // 모든 사용자 불러오기
    axios.get("/api/users")
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error("사용자 정보를 불러오는 중 오류 발생:", error);
      });
  }, []);

  const openModal = (user) => {
    setSelectedUser(user);
    setPointDetails({
      userId: user.user_id,
      points: 0,
      reason: ""
    });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedUser(null);
  };

  const handlePointChange = (e) => {
    setPointDetails({ ...pointDetails, points: e.target.value });
  };

  const handleReasonChange = (e) => {
    setPointDetails({ ...pointDetails, reason: e.target.value });
  };

  const handleSubmit = () => {
    axios.post("/api/points/distribute", pointDetails)
      .then(response => {
        alert("포인트가 성공적으로 지급되었습니다!");
        closeModal();
      })
      .catch(error => {
        console.error("포인트 지급 중 오류 발생:", error);
      });
  };

  return (
    <div>
      <h1>관리자 페이지</h1>
      <table>
        <thead>
          <tr>
            <th>사용자 ID</th>
            <th>닉네임</th>
            <th>이메일</th>
            <th>현재 포인트</th>
            <th>작업</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.user_id}>
              <td>{user.user_id}</td>
              <td>{user.user_nickname}</td>
              <td>{user.user_email}</td>
              <td>{user.user_point}</td>
              <td>
                <button onClick={() => openModal(user)}>포인트 지급</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="포인트 지급"
      >
        <h2>{selectedUser?.user_nickname}에게 포인트 지급</h2>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <div>
            <label>지급할 포인트:</label>
            <input
              type="number"
              value={pointDetails.points}
              onChange={handlePointChange}
              required
            />
          </div>
          <div>
            <label>포인트 지급 이유:</label>
            <textarea
              value={pointDetails.reason}
              onChange={handleReasonChange}
              required
            />
          </div>
          <button type="submit">제출</button>
          <button type="button" onClick={closeModal}>취소</button>
        </form>
      </Modal>
    </div>
  );
};

export default AdminPage;
