import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import styles from './QuestionPage.module.css';

import prevButtonImage from '../../Image/Button/prev_button.svg';
import nextButtonImage from '../../Image/Button/next_button.svg';
import SubmitButtonImage from '../../Image/Button/Submit.svg';

import TicketViewImage from '../../Image/Button/Ticket_View.svg';

// 감정도장 이미지 
import Joy from '../../Image/Emotional_stamp/stamp_JOY.png';
import SADNESS from '../../Image/Emotional_stamp/stamp_SADNESS.png';
import ANGER from '../../Image/Emotional_stamp/stamp_ANGER.png';
import DISGUST from '../../Image/Emotional_stamp/stamp_DISGUST.png';
import FEAR from '../../Image/Emotional_stamp/stamp_FEAR.png';
import ANXIETY from '../../Image/Emotional_stamp/stamp_ANXIETY.png';
import EMBARRASSMENT from '../../Image/Emotional_stamp/stamp_EMBARRASSMENT.png';
import ENNUI from '../../Image/Emotional_stamp/stamp_ENNUI.png';
import ENVY from '../../Image/Emotional_stamp/stamp_ENVY.png';

// 도착 스테이지

import { getRoundMessage } from '../Component/RoundMessages';


// 도착 이미지

import { getImageByRound } from '../Component/Images';


const QuestionPage = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userId = useSelector((state) => state.auth.userId);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [warmupQuestion, setWarmupQuestion] = useState(null);
  const [responseText, setResponseText] = useState(Cookies.get('response_text') || '');
  const [selectedEmotions, setSelectedEmotions] = useState([]);
  const [selectedWarmupOptions, setSelectedWarmupOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [charCount, setCharCount] = useState(responseText.length);
  const navigate = useNavigate();
  const { id } = useParams();
  const [hasResponded, setHasResponded] = useState(false);

  const emotions = [
    { id: 1, name: '기쁨', image: Joy },
    { id: 2, name: '슬픔', image: SADNESS },
    { id: 3, name: '버럭', image: ANGER },
    { id: 4, name: '까칠', image: DISGUST },
    { id: 5, name: '소심', image: FEAR },
    { id: 6, name: '불안', image: ANXIETY },
    { id: 7, name: '당황', image: EMBARRASSMENT },
    { id: 8, name: '따분', image: ENNUI },
    { id: 9, name: '부럽', image: ENVY },
  ];

  const handleTicketViewClick = () => {
    navigate('/mypage', { replace: true }); // replace: true 옵션을 사용하여 뒤로가기를 막습니다.
  };

  const fetchQuestionData = async () => {
    try {
      // 응답 여부 확인 API 호출
      const responseCheck = await axios.get(`/api/response/check`, {
        params: {
          user_id: userId,
          question_id: id
        }
      });
      if (responseCheck.data.hasResponded) {
        setHasResponded(true);
        setStep(4);  // 응답이 이미 존재하는 경우 4번째 단계로 이동
        return;
      }

      const response = await axios.get(`/api/question/${id}`);
      if (response.data) {
        setCurrentQuestion(response.data.question);
        setWarmupQuestion(response.data.warmupQuestion);
      } else {
        setCurrentQuestion(null);
        setWarmupQuestion(null);
      }
    } catch (error) {
      console.error('Error fetching question data:', error);
      if (error.response) {
        switch (error.response.status) {
          case 404:
            setCurrentQuestion(null);
            setWarmupQuestion(null);
            break;
          case 401:
            navigate('/login');
            break;
          default:
        }
      } else {
        console.log('Network or other error:', error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (step === 4) {
      console.log('Initializing state after completion');
  
      // 상태 초기화
      Cookies.remove('response_text');  // 쿠키 제거
      setResponseText('');
      setSelectedEmotions([]);
      setSelectedWarmupOptions({});
      // setStep(1); // 기본 상태로 초기화 (단, 새로 질문을 작성할 때만)
    }
  }, [step]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchQuestionData();
    } else {
      navigate('/login');
    }
  }, [isLoggedIn, navigate, id]);

  useEffect(() => {
    if (step !== 3 && hasResponded && step !== 4) {  // 3번째 단계가 아니고 4번째 단계가 아닐 때만 경고창 표시
      alert("이미 응답한 질문입니다.");
      // setStep(4);  // 응답이 이미 존재하는 경우 4번째 단계로 이동

      navigate('/main');
    }
  }, [hasResponded, step, navigate]);

  useEffect(() => {
    Cookies.set('response_text', responseText, { expires: 1 / 24 });
  }, [responseText]);

  const handleWarmupOptionChange = (questionId, option) => {
    setSelectedWarmupOptions({
      ...selectedWarmupOptions,
      [questionId]: option,
    });
  };

  const handleNextStep = () => {
    if (step === 1 && selectedWarmupOptions[warmupQuestion.warmup_question_id]) {
      setStep(2);
    } else if (step === 2 && responseText) {
      setStep(3);
    }
  };

  const toggleEmotionSelection = (emotionId) => {
    if (selectedEmotions.includes(emotionId)) {
      setSelectedEmotions(selectedEmotions.filter(e => e !== emotionId));
    } else if (selectedEmotions.length < 3) {
      setSelectedEmotions([...selectedEmotions, emotionId]);
    }
  };

  const handleFinalSubmit = async () => {
    if (currentQuestion && responseText && selectedEmotions.length > 0) {
      try {
        const response = await axios.post('/api/save-response', {
          question_id: currentQuestion.question_id,
          response_text: responseText,
          warmup_responses: [
            {
              warmup_question_id: warmupQuestion.warmup_question_id,
              warmup_sel_option: selectedWarmupOptions[warmupQuestion.warmup_question_id],
            }
          ],
          emotions: selectedEmotions,
        });

        console.log('Response submission successful:', response.data);

        try {
          const pointsResponse = await axios.post('/api/points', {
            charCount: responseText.length,
            question_id: currentQuestion.question_id
          });
          console.log('Points awarded:', pointsResponse.data);
          alert('응답이 제출되었고 포인트가 지급되었습니다.');

           // 응답 제출 및 포인트 지급이 성공하면 4번째 단계로 이동
          console.log("Moving to step 4..."); // 단계 이동 로그 출력
          setStep(4);

          // 제출 후 메인 페이지로 이동
          // navigate('/main');

        } catch (pointsError) {
          console.error('포인트 지급 중 오류 발생:', pointsError.response ? pointsError.response.data : pointsError);
          alert(`응답은 제출되었지만 포인트 지급 중 오류가 발생했습니다: ${pointsError.response ? JSON.stringify(pointsError.response.data) : pointsError.message}`);

          // 포인트 지급 중 오류가 발생하더라도 메인 페이지로 이동
          // navigate('/main');

           // 포인트 지급 중 오류가 발생하더라도 4번째 단계로 이동
          setStep(4);
        }

        // // 쿠키 제거 및 초기화
        // Cookies.remove('response_text');
        // setResponseText('');
        // setSelectedEmotions([]);
        // setSelectedWarmupOptions({});
        // setStep(1);
        // fetchQuestionData();

      } catch (error) {
        console.error('응답 제출 중 오류가 발생했습니다:', error.response ? error.response.data : error);
        alert(`응답 제출 중 오류가 발생했습니다: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
      }
    } else {
      alert('모든 항목을 작성해 주세요.');
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= 300) {
      setResponseText(text);
      setCharCount(text.length);
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  return (
    <div className={styles.questionPage}>
      {step === 1 && warmupQuestion ? (
        <div className={styles.warmupSection}>
          <h1>QUEST {String(warmupQuestion.warmup_round_number).padStart(2, '0')}</h1>
          <div key={warmupQuestion.warmup_question_id} className={styles.warmupQuestion}>
            <h2>밸런스 게임 A or B?</h2>
            <div className={styles.options}>
              <button
                className={`${styles.buttonText} ${selectedWarmupOptions[warmupQuestion.warmup_question_id] === warmupQuestion.warmup_option1 ? styles.selected : ''}`}
                onClick={() => handleWarmupOptionChange(warmupQuestion.warmup_question_id, warmupQuestion.warmup_option1)}
              >
                {warmupQuestion.warmup_option1}
              </button>
              <button
                className={`${styles.buttonText} ${selectedWarmupOptions[warmupQuestion.warmup_question_id] === warmupQuestion.warmup_option2 ? styles.selected : ''}`}
                onClick={() => handleWarmupOptionChange(warmupQuestion.warmup_question_id, warmupQuestion.warmup_option2)}
              >
                {warmupQuestion.warmup_option2}
              </button>
            </div>
          </div>
          <div className={styles.buttonSection}>
            <img src={prevButtonImage} alt="이전으로" onClick={() => navigate('/main')} />
            <img src={nextButtonImage} alt="다음으로" onClick={handleNextStep} />
          </div>
        </div>
      ) : step === 2 && currentQuestion ? (
        <div className={styles.container}>
          <div className={styles.questionSection}>
            <p className={styles.number}>QUEST {String(currentQuestion.question_round_number).padStart(2, '0')}</p>
            <p className={styles.question}>{currentQuestion.question_text}</p>
          </div>
          <div className={styles.answerSection}>
            <textarea
              className={styles.answerContent}
              value={responseText}
              onChange={handleTextChange}
              placeholder="답변 내용을 입력하세요..."
            />
            <p className={styles.charCount}>{charCount} / 300</p>
          </div>
          <div className={styles.buttonSection}>
            <img src={prevButtonImage} alt="이전으로" onClick={() => setStep(1)} />
            <img src={nextButtonImage} alt="다음으로" onClick={handleNextStep} />
          </div>
        </div>
      ) : step === 3 ? (
        <>
          <div className={styles.emotionSelection}>
          <h1>QUEST {String(warmupQuestion.warmup_round_number).padStart(2, '0')}</h1>
            <h2>퀘스트를 수행하며 어떤 감정을 느끼셨나요? (최대 3개)</h2>
            <div className={styles.emotionButtonsContainer}>
            <div className={styles.emotionButtonsRow}>
              {emotions.map((emotion) => (
                <div key={emotion.id} className={styles.emotionButtonWrapper}>
                  <button
                    className={`${styles.emotionButton} ${selectedEmotions.includes(emotion.id) ? styles.selected : ''}`}
                    onClick={() => toggleEmotionSelection(emotion.id)}
                    style={{ backgroundImage: `url(${emotion.image})` }}
                  />
                  <div className={styles.emotionLabel}>{emotion.name}</div>
                </div>
              ))}
            </div>
            </div>
          </div>
          <div className={styles.buttonSection}>
            <img src={prevButtonImage} alt="이전으로" onClick={() => setStep(2)} />
            <img src={SubmitButtonImage} alt="제출하기" onClick={handleFinalSubmit} />
          </div>
        </>
      ) :   step === 4 ? (
        <div className={styles.confirmationPage}>
            <img src={getImageByRound((currentQuestion?.question_round_number || 1) - 1)} alt="Quest Completion Image" className={styles.completionImage} />

          <div className={styles.Checktext}>
            <h1 className={styles.ChecktextMessage}>{getRoundMessage((currentQuestion?.question_round_number || 1) - 1)}에 도착했어요!</h1>
            <p className={styles.Textpoint}>+ 500 Point</p>
          </div>
          {/* <div className={styles.buttonSectionTiket}> */}
            <img src={TicketViewImage} alt="티켓 보기" className={styles.ticketview}  onClick={handleTicketViewClick} />
          {/* </div> */}
        </div>
      ) : (
        <p>활성화된 질문이 없습니다.</p>
      )}
      </div>
  );
};

export default QuestionPage;
