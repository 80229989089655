import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { login } from '../../redux/actions/authActions'; // login 액션 임포트
import styles from './login.module.css'; // CSS 모듈 임포트
import LoginImage from '../../Image/Button/login_button.svg'; // 이미지 임포트

const Login = () => {
  const [formData, setFormData] = useState({ user_login_id: '', user_login_pw: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(login(formData.user_login_id, formData.user_login_pw));
      navigate('/main'); // 로그인 성공 시 메인 페이지로 이동
    } catch (error) {
      console.error('Login submission error:', error);
    }
  };

  return (
    <div className={styles.loginPageContainer}>
      <div className={styles.loginPage}>
        <div className={styles.loginContainer}>
          <h1 className={styles.texth1}>로그인</h1>
          <form onSubmit={handleSubmit} className={styles.loginForm}>
            <input
              type="text"
              name="user_login_id"
              placeholder="아이디"
              value={formData.user_login_id}
              onChange={handleChange}
              className={styles.inputField}
            />
            <input
              type="password"
              name="user_login_pw"
              placeholder="비밀번호"
              value={formData.user_login_pw}
              onChange={handleChange}
              className={styles.inputField}
            />
            <div className={styles.helpLinks}>
              <p>계정이 기억나지 않나요?</p>
              <p>
                <a href="./findid">아이디 찾기</a> | <a href='./findpw'>비밀번호 찾기</a>
              </p>
            </div>
            <button type="submit" className={styles.loginButton}>
              <img src={LoginImage} alt="로그인 버튼" className={styles.buttonImage} />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
