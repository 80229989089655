import React, { useState, useEffect } from 'react';
import styles from './Leaderboard.module.css';

function Leaderboard() {
  const [personalRanking, setPersonalRanking] = useState([]);
  const [teamRanking, setTeamRanking] = useState([]);
  const [activeTab, setActiveTab] = useState('personal');

  useEffect(() => {
    fetch('/api/personal-ranking')
      .then(response => response.json())
      .then(data => setPersonalRanking(data));

    fetch('/api/team-ranking')
      .then(response => response.json())
      .then(data => setTeamRanking(data));
  }, []);

  const renderPersonalRanking = () => {
    return personalRanking.map((user, index) => (
      <div key={index} className={styles.leaderboardRankItem}>
        <span className={styles.leaderboardRank}>{user.rank}</span>
        <span className={styles.leaderboardNickname}>{user.user_nickname}</span>
        <span className={styles.leaderboardPoints}>{user.user_point} 포인트</span>
      </div>
    ));
  };

  const renderTeamRanking = () => {
    return teamRanking.map((team, index) => (
      <div key={index} className={styles.leaderboardRankItem}>
        <span className={styles.leaderboardRank}>{team.team_rank}</span> {/* 수정된 부분 */}
        <span className={styles.leaderboardNickname}>{team.team_name}</span>
        <span className={styles.leaderboardPoints}>{team.team_points} 포인트</span>
      </div>
    ));
  };
  




  return (
    <div className={styles.leaderboardPage}>
      <div className={styles.leaderboardContainer}>
        <div className={styles.leaderboardHeader}>
          <h1>랭킹</h1>
        </div>
        <div className={styles.leaderboardTabsContainer}>
          <div className={styles.leaderboardTabs}>
            <button
              className={`${styles.leaderboardTabButton} ${activeTab === 'personal' ? styles.active : styles.inactive}`}
              onClick={() => setActiveTab('personal')}
            >
              개인 랭킹
            </button>
            <button
              className={`${styles.leaderboardTabButton} ${activeTab === 'team' ? styles.active : styles.inactive}`}
              onClick={() => setActiveTab('team')}
            >
              팀 랭킹
            </button>
          </div>
          <div
            className={styles.leaderboardTabContent}
            style={{ display: activeTab === 'personal' ? 'block' : 'none' }}
          >
            {renderPersonalRanking()}
          </div>
          <div
            className={styles.leaderboardTabContent}
            style={{ display: activeTab === 'team' ? 'block' : 'none' }}
          >
            {renderTeamRanking()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Leaderboard;
