import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Membershipmanage.module.css';

const MembershipManage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [teams, setTeams] = useState([]);
  const [points, setPoints] = useState('');
  const [pointDescription, setPointDescription] = useState('');

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/admin/users?page=${page}&limit=10`, { withCredentials: true });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
    setLoading(false);
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get('/api/admin/teams', { withCredentials: true });
      console.log(response.data); // 데이터 구조 확인
      setTeams(response.data.teams || []);
    } catch (error) {
      console.error('Failed to fetch teams:', error);
      setTeams([]); // 오류 발생 시 teams를 빈 배열로 설정
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchTeams();
  }, [page]);

  const handleApprove = async (userId) => {
    try {
      await axios.post('/api/admin/approve', { user_id: userId }, { withCredentials: true });
      fetchUsers();
    } catch (error) {
      console.error('Failed to approve user:', error);
    }
  };

  const handleManage = async (userId) => {
    try {
      const response = await axios.get(`/api/admin/user/${userId}`, { withCredentials: true });
      if (response.data.user) {
        setSelectedUser(response.data.user);
      } else {
        console.log('No user data found for user id:', userId);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleTeamChange = async (event) => {
    const newTeamId = event.target.value;
    try {
      await axios.post('/api/admin/update-team', { user_id: selectedUser.user_id, team_id: newTeamId }, { withCredentials: true });
      setSelectedUser({ ...selectedUser, user_team_id: newTeamId });
    } catch (error) {
      console.error('Failed to update team:', error);
    }
  };

  const handlePointSubmit = async () => {
    try {
      await axios.post('/api/admin/add-points', {
        user_id: selectedUser.user_id,
        point_provision: parseInt(points, 10),
        point_description: pointDescription
      }, { withCredentials: true });
      setPoints('');
      setPointDescription('');
      fetchUsers();
    } catch (error) {
      console.error('Failed to add points:', error);
    }
  };

  const closeModal = () => {
    setSelectedUser(null);
  };

  return (
    <div className={styles.membershipManage}>
      <h1>회원 관리</h1>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.th}>구분</th>
            <th className={styles.th}>아이디</th>
            <th className={styles.th}>이메일</th>
            <th className={styles.th}>사용자 구분</th>
            <th className={styles.th}>최근 접속 일시</th>
            <th className={styles.th}>팀</th>
            <th className={styles.th}>관리</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.user_id}>
              <td className={styles.td}>{String((page - 1) * 10 + index + 1).padStart(2, '0')}</td>
              <td className={styles.td}>{user.user_nickname}</td>
              <td className={styles.td}>{user.user_email}</td>
              <td className={styles.td}>{user.user_role === 'admin' ? '관리자' : '사용자'}</td>
              <td className={styles.td}>{user.last_login_time ? new Date(user.last_login_time).toLocaleString() : 'N/A'}</td>
              <td className={styles.td}>{user.team_name || 'N/A'}</td>
              <td className={styles.td}>
                {user.user_is_approved ? (
                  <button className={styles.button} onClick={() => handleManage(user.user_id)}>관리</button>
                ) : (
                  <button className={`${styles.button} ${styles.approveButton}`} onClick={() => handleApprove(user.user_id)}>
                    승인
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <div>Loading...</div>}
      <div className={styles.pagination}>
        <button className={styles.paginationButton} onClick={handlePreviousPage} disabled={page === 1}>
          이전
        </button>
        <span>{page} / {totalPages}</span>
        <button className={styles.paginationButton} onClick={handleNextPage} disabled={page === totalPages}>
          다음
        </button>
      </div>

      {selectedUser && (
        <div className={styles.modal}>
          <h2 className={styles.modalTitle}>회원 관리</h2>
          <button className={styles.closeButton} onClick={closeModal}>X</button>
          <p>아이디: {selectedUser.user_login_id}</p>
          <p>이메일: {selectedUser.user_email}</p>
          <p>닉네임: {selectedUser.user_nickname}</p>
          <label className={styles.label}>
            팀:
            <select className={styles.select} value={selectedUser.user_team_id || ''} onChange={handleTeamChange}>
              {teams && teams.map(team => (
                <option key={team.team_id} value={team.team_id}>{team.team_name}</option>
              ))}
            </select>
          </label>
          <p>현재 포인트: {selectedUser.user_point} 포인트</p>
          <label className={styles.label}>
            포인트 부여하기:
            <input
              className={styles.input}
              type="number"
              value={points}
              onChange={e => setPoints(e.target.value)}
              placeholder="부여할 포인트를 입력하세요"
              min="1"
            />
          </label>
          <label className={styles.label}>
            포인트 지급 이유:
            <input
              className={styles.input}
              type="text"
              value={pointDescription}
              onChange={e => setPointDescription(e.target.value)}
              placeholder="포인트 지급 이유를 입력하세요"
            />
          </label>
          <button className={styles.submitButton} onClick={handlePointSubmit}>확인</button>
          <p>최근 접속 시간: {selectedUser.last_login_time ? new Date(selectedUser.last_login_time).toLocaleString() : 'N/A'}</p>
          <p>최근 로그아웃 시간: {selectedUser.last_logout_time ? new Date(selectedUser.last_logout_time).toLocaleString() : 'N/A'}</p>
        </div>
      )}
    </div>
  );
};

export default MembershipManage;