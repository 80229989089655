// src/hooks/useAuth.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const useAuth = () => {
  const [auth, setAuth] = useState({ isLoggedIn: false, userRole: null, loading: true });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const result = await axios.get('/api/check-auth');
        if (result.data.loggedIn) {
          setAuth({ isLoggedIn: true, userRole: result.data.userRole, loading: false });
        } else {
          setAuth({ isLoggedIn: false, userRole: null, loading: false });
        }
      } catch (error) {
        setAuth({ isLoggedIn: false, userRole: null, loading: false });
      }
    };

    checkAuth();
  }, []);

  return auth;
};

export default useAuth;
