import axios from 'axios';

export const checkAuth = () => async (dispatch) => {
  console.log('checkAuth action dispatched');
  try {
    const result = await axios.get('/api/check-auth');
    if (result.data.loggedIn) {
      console.log('User is logged in:', result.data);
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: { userId: result.data.userId, userRole: result.data.userRole },
      });
    } else {
      console.log('User is not logged in');
      dispatch({
        type: 'LOGOUT_SUCCESS',
      });
    }
  } catch (error) {
    console.error('Error during checkAuth:', error);
  }
};

export const login = (loginId, loginPw) => async (dispatch) => {
  try {
    const response = await axios.post('/api/login', { user_login_id: loginId, user_login_pw: loginPw });
    if (response.status === 200) {
      console.log('Login successful:', response.data);
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: { userId: response.data.userId, userRole: response.data.userRole },
      });
      alert('로그인 성공');
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      alert('관리자의 승인을 기다리는 중입니다');
    } else {
      console.error('Login error:', error);
      alert('로그인 실패');
    }
  }
};

export const logout = () => async (dispatch) => {
  try {
    await axios.post('/api/logout');
    dispatch({
      type: 'LOGOUT_SUCCESS',
    });
  } catch (error) {
    console.error('Logout error:', error);
  }
};
