import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import styles from './QuestionPage.module.css';

const QuestionPage = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [warmupQuestion, setWarmupQuestion] = useState(null);
  const [responseText, setResponseText] = useState(Cookies.get('response_text') || '');
  const [selectedEmotions, setSelectedEmotions] = useState([]);
  const [selectedWarmupOptions, setSelectedWarmupOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [charCount, setCharCount] = useState(responseText.length); // 글자 수 상태 추가
  const navigate = useNavigate();

  const fetchQuestionData = async () => {
    try {
      const response = await axios.get('/api/question');
      if (response.data) {
        setCurrentQuestion(response.data.question);
        setWarmupQuestion(response.data.warmupQuestion);
      } else {
        setCurrentQuestion(null);
        setWarmupQuestion(null);
      }
    } catch (error) {
      console.error('Error fetching question data:', error);
      if (error.response) {
        switch (error.response.status) {
          case 404:
            setCurrentQuestion(null);
            setWarmupQuestion(null);
            break;
          case 401:
            navigate('/login');
            break;
          default:
        }
      } else {
        console.log('Network or other error:', error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchQuestionData();
    } else {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    Cookies.set('response_text', responseText, { expires: 1 / 24 }); // 1시간 동안 저장
  }, [responseText]);

  const handleWarmupOptionChange = (questionId, option) => {
    setSelectedWarmupOptions({
      ...selectedWarmupOptions,
      [questionId]: option,
    });
  };

  const handleNextStep = () => {
    if (step === 1 && selectedWarmupOptions[warmupQuestion.warmup_question_id]) {
      setStep(2);
    } else if (step === 2 && responseText) {
      setStep(3);
    }
  };

  const toggleEmotionSelection = (emotionId) => {
    if (selectedEmotions.includes(emotionId)) {
      setSelectedEmotions(selectedEmotions.filter(e => e !== emotionId));
    } else if (selectedEmotions.length < 3) {
      setSelectedEmotions([...selectedEmotions, emotionId]);
    }
  };

  const handleFinalSubmit = async () => {
    if (currentQuestion && responseText && selectedEmotions.length > 0) {
      try {
        const response = await axios.post('/api/response', {
          question_id: currentQuestion.question_id,
          response_text: responseText,
          warmup_responses: [
            {
              warmup_question_id: warmupQuestion.warmup_question_id,
              warmup_sel_option: selectedWarmupOptions[warmupQuestion.warmup_question_id],
            }
          ],
          emotions: selectedEmotions,
        });
  
        console.log('Response submission successful:', response.data);
  
        // 포인트 지급 API 호출
        try {
          const pointsResponse = await axios.post('/api/points', {
            charCount: responseText.length,
            question_id: currentQuestion.question_id
          });
          console.log('Points awarded:', pointsResponse.data);
          alert('응답이 제출되었고 포인트가 지급되었습니다.');
        } catch (pointsError) {
          console.error('포인트 지급 중 오류 발생:', pointsError.response ? pointsError.response.data : pointsError);
          alert(`응답은 제출되었지만 포인트 지급 중 오류가 발생했습니다: ${pointsError.response ? JSON.stringify(pointsError.response.data) : pointsError.message}`);
        }
  
        Cookies.remove('response_text'); // 쿠키 제거
        setResponseText('');
        setSelectedEmotions([]);
        setSelectedWarmupOptions({});
        setStep(1);
        fetchQuestionData();
      } catch (error) {
        console.error('응답 제출 중 오류가 발생했습니다:', error.response ? error.response.data : error);
        alert(`응답 제출 중 오류가 발생했습니다: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
      }
    } else {
      alert('모든 항목을 작성해 주세요.');
    }
  };

  const handleTextChange = (e) => {
    const text = e.target.value;
    if (text.length <= 300) {
      setResponseText(text);
      setCharCount(text.length);
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  return (
    <div className={styles.questionPage}>
      {step === 1 && warmupQuestion ? (
        <div className={styles.warmupSection}>
          <h1>QUEST {String(warmupQuestion.warmup_round_number).padStart(2, '0')}</h1>
          <div key={warmupQuestion.warmup_question_id} className={styles.warmupQuestion}>
            <p>{warmupQuestion.warmup_question_text}</p>
            <div className={styles.options}>
              <button
                className={selectedWarmupOptions[warmupQuestion.warmup_question_id] === warmupQuestion.warmup_option1 ? styles.selected : ''}
                onClick={() => handleWarmupOptionChange(warmupQuestion.warmup_question_id, warmupQuestion.warmup_option1)}
              >
                {warmupQuestion.warmup_option1}
              </button>
              <button
                className={selectedWarmupOptions[warmupQuestion.warmup_question_id] === warmupQuestion.warmup_option2 ? styles.selected : ''}
                onClick={() => handleWarmupOptionChange(warmupQuestion.warmup_question_id, warmupQuestion.warmup_option2)}
              >
                {warmupQuestion.warmup_option2}
              </button>
            </div>
          </div>
          <div className={styles.buttonSection}>
            <button onClick={() => navigate('/main')}>이전으로</button>
            <button onClick={handleNextStep}>다음으로</button>
          </div>
        </div>
      ) : step === 2 && currentQuestion ? (
        <div className={styles.container}>
          <div className={styles.questionSection}>
            <p className={styles.number}>QUEST {String(currentQuestion.question_round_number).padStart(2, '0')}</p>
            <p className={styles.question}>{currentQuestion.question_text}</p>
          </div>
          <div className={styles.answerSection}>
            <textarea
              className={styles.answerContent}
              value={responseText}
              onChange={handleTextChange}
              placeholder="답변 내용을 입력하세요..."
            />
            <p>{charCount} / 300</p> {/* 글자 수 표시 */}
          </div>
          <div className={styles.buttonSection}>
            <button onClick={() => setStep(1)}>이전으로</button>
            <button onClick={handleNextStep}>다음으로</button>
          </div>
        </div>
      ) : step === 3 ? (
        <div className={styles.emotionSelection}>
          <h2>퀘스트를 수행하며 어떤 감정을 느끼셨나요? (최대 3개) </h2>
          <div className={styles.emotionButtons}>
            {[
              { id: 1, name: '기쁨' }, { id: 2, name: '슬픔' }, { id: 3, name: '버럭' },
              { id: 4, name: '까칠' }, { id: 5, name: '소심' }, { id: 6, name: '불안' },
              { id: 7, name: '당황' }, { id: 8, name: '따분' }, { id: 9, name: '부럽' }
            ].map((emotion, index) => (
              <button
                key={emotion.id}
                className={selectedEmotions.includes(emotion.id) ? styles.selected : ''}
                onClick={() => toggleEmotionSelection(emotion.id)}
                style={{ backgroundColor: `hsl(${index * 40}, 70%, 50%)` }}
              >
                {emotion.name}
              </button>
            ))}
          </div>
          <div className={styles.buttonSection}>
            <button onClick={() => setStep(2)}>이전으로</button>
            <button onClick={handleFinalSubmit}>제출하기</button>
          </div>
        </div>
      ) : (
        <p>활성화된 질문이 없습니다.</p>
      )}
    </div>
  );
};

export default QuestionPage;
