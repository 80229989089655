// 21개의 이미지 파일 import
import island1 from '../../Image/Emblem/island1.png';
import island2 from '../../Image/Emblem/island2.png';
import island3 from '../../Image/Emblem/island3.png';
import island4 from '../../Image/Emblem/island4.png';
import island5 from '../../Image/Emblem/island5.png';
import island6 from '../../Image/Emblem/island6.png';
import island7 from '../../Image/Emblem/island7.png';
import island8 from '../../Image/Emblem/island8.png';
import island9 from '../../Image/Emblem/island9.png';
import island10 from '../../Image/Emblem/island10.png';
import island11 from '../../Image/Emblem/island11.png';
import island12 from '../../Image/Emblem/island12.png';
import island13 from '../../Image/Emblem/island13.png';
import island14 from '../../Image/Emblem/island14.png';
import island15 from '../../Image/Emblem/island15.png';
import island16 from '../../Image/Emblem/island16.png';
import island17 from '../../Image/Emblem/island17.png';
import island18 from '../../Image/Emblem/island18.png';
import island19 from '../../Image/Emblem/island19.png';
import island20 from '../../Image/Emblem/island20.png';
import island21 from '../../Image/Emblem/island21.png';

const images = [
    island1, island2, island3, island4, island5, island6, island7, island8, island9, island10,
    island11,island12,island13,island14,island15,island16,island17,island18,island19,island20,island21
  ];

export const getImageByRound = (roundNumber) => {
    return images[roundNumber] || images[0];  // roundNumber가 없는 경우 기본 이미지 반환
};