import React, { useEffect, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './TEST_MY.module.css'; // 스타일 모듈을 가져옵니다.

// 필요한 이미지를 미리 불러옵니다.
import island1 from '../../Image/Emblem/island1.png';
import island2 from '../../Image/Emblem/island2.png';
import island3 from '../../Image/Emblem/island3.png';
import island4 from '../../Image/Emblem/island4.png';
import island5 from '../../Image/Emblem/island5.png';
import island6 from '../../Image/Emblem/island6.png';
import island7 from '../../Image/Emblem/island7.png';
import island8 from '../../Image/Emblem/island8.png';
import island9 from '../../Image/Emblem/island9.png';
import island10 from '../../Image/Emblem/island10.png';
import island11 from '../../Image/Emblem/island11.png';

// 새로 추가한 이미지 경로
// import companyLogo1 from '../../Image/Logo/logo1.png';
// import companyLogo2 from '../../Image/Logo/logo2.png';

import COIN from '../../Image/Mypage/COIN.svg'
import MY from '../../Image/Mypage/MY.svg'
import TEAM from '../../Image/Mypage/TEAM.png'

const images = {
  1: island1,
  2: island2,
  3: island3,
  4: island4,
  5: island5,
  6: island6,
  7: island7,
  8: island8,
  9: island9,
  10: island10,
  11: island11,
};

const TicketCard = ({ question, onClick }) => {
  const formattedDate = new Date(question.question_created_at).toLocaleDateString();
  const imageUrl = images[question.question_round_number];

  return (
    <div className={styles.ticketCard} onClick={onClick}>
      <div className={styles.ticketCardLeftSection}>
        <div className={styles.ticketCardFlightInfo}>
          <span>FLIGHT QUEST {question.question_round_number}</span>
        </div>
        <div className={styles.ticketCardDateInfo}>
          <span>DATE {formattedDate}</span>
        </div>
        <p>{question.question_text}</p>
        <div className={styles.barcode}></div>
      </div>
      <div className={styles.ticketCardRightSection}>
        {imageUrl && <img src={imageUrl} alt="Trip" />}
      </div>
    </div>
  );
};

// 간단한 reducer 함수 추가
const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return { ...state, isLoggedOut: true };
    default:
      return state;
  }
};

function MyPage() {
  const [userData, setUserData] = useState(null);
  const [emotionsCount, setEmotionsCount] = useState([
    { emotion_name: '기쁨', count: 0 },
    { emotion_name: '슬픔', count: 0 },
    { emotion_name: '버럭', count: 0 },
    { emotion_name: '까칠', count: 0 },
    { emotion_name: '소심', count: 0 },
    { emotion_name: '불안', count: 0 },
    { emotion_name: '당황', count: 0 },
    { emotion_name: '따분', count: 0 },
    { emotion_name: '부럽', count: 0 },
  ]);
  const [recentQuestions, setRecentQuestions] = useState([]);
  const [missedQuestions, setMissedQuestions] = useState([]);
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, { isLoggedOut: false });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user data
        const userDataResponse = await fetch('/api/user-data');
        const userData = await userDataResponse.json();
        setUserData(userData);

        // Fetch emotions count
        const emotionsCountResponse = await fetch('/api/emotions-count');
        const emotionsCountData = await emotionsCountResponse.json();
        const updatedEmotionsCount = [...emotionsCount];
        emotionsCountData.forEach(emotion => {
          const index = updatedEmotionsCount.findIndex(e => e.emotion_name === emotion.emotion_name);
          if (index !== -1) {
            updatedEmotionsCount[index].count = emotion.count;
          }
        });
        setEmotionsCount(updatedEmotionsCount);

        // Fetch recent questions
        const recentQuestionsResponse = await fetch('/api/recent-questions');
        const recentQuestionsData = await recentQuestionsResponse.json();
        setRecentQuestions(recentQuestionsData);

        // Fetch missed questions
        const missedQuestionsResponse = await fetch('/api/missed-questions');
        const missedQuestionsData = await missedQuestionsResponse.json();
        setMissedQuestions(missedQuestionsData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    axios.post('/api/logout')
      .then(() => {
        dispatch({ type: 'LOGOUT' });
        navigate('/login');
        window.location.reload(); // 페이지 새로고침
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.MyPage}>

        <div className={styles.mypageContainer}>
            <div className={styles.hello}>
            <h2>반가워요! 퀘스터 {userData.user_nickname}님</h2>
            </div>
        </div>


        <div className={styles.leftPanel}>
            <h3>퀘스터</h3>
            <h2 className={styles.usernickname}>{userData.user_nickname}</h2>
            <p>{userData.team_name ? userData.team_name : '팀이 아직 지정되지 않았습니다.'}</p>

                <div className={styles.records} onClick={() => navigate('/responses')}>
                    <button>여행기록보기</button>
                </div>

            <div className={styles.PointPanel}>
                <div className={styles.stat} onClick={() => navigate('/pointhistory')}>
                    <span>포인트</span>
                    <img src={COIN} alt="point icon" />
                    <span>{userData.user_point}</span>
                </div>
            </div>

            <div className={styles.RankPanelPER} onClick={() => navigate('/Leaderboard')}>
                <span>개인 랭킹</span>
                <img src={MY} alt="rank icon" />
                <span>{userData.personal_rank}</span>
            </div>

            <div className={styles.RankPanelTEAM} onClick={() => navigate('/Leaderboard')}>
                <span>팀 랭킹</span>
                <img src={TEAM} alt="rank icon" />
                <span>{userData.team_rank}</span>
            </div>

            <div className={styles.userActions}>
                <button onClick={handleLogout}>로그아웃</button>
            </div>
        </div>
        
            <div className={styles.TravelPanel}>

                <div className={styles.recentTrips}>
                    <h2>최근 여행지</h2>
                    {recentQuestions.slice(0, 1).map(question => (
                    <TicketCard key={question.question_id} question={question} />
                    ))}
                </div>

                <div className={styles.unansweredTrips}>
                    <h2>놓친 여행지</h2>
                    {missedQuestions.map(question => (
                    <TicketCard 
                        key={question.question_id} 
                        question={question}
                        onClick={() => navigate(`/answer/${question.question_id}`)} 
                    />
                    ))}
                </div>
            </div>
    </div>
  );
}

export default MyPage;
