import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import styles from './Register.module.css'; // CSS 모듈 임포트
import { useNavigate } from 'react-router-dom';

import BackButtonImage from '../../Image/Button/prev_button.svg'; // '이전으로' 버튼 이미지 경로
import NextButtonImage from '../../Image/Button/next_button.svg' // 인증
import CheckButtonImage from '../../Image/Button/Check_redundancy.png' // 인증
import RegisterButtonImage from '../../Image/Button/register.svg' // 인증

Modal.setAppElement('#root');

function Register() {
  const navigate = useNavigate(); // useNavigate 훅 정의
  const [formData, setFormData] = useState({
    user_nickname: '',
    user_email: '',
    user_login_id: '',
    user_login_pw: '',
    user_login_pw_confirm: '' // 비밀번호 재확인 필드 추가
  });
  const [step, setStep] = useState(1); // 현재 단계를 추적하는 상태
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태
  const [termsAccepted, setTermsAccepted] = useState(false); // 이용약관 동의 상태
  const [nicknameChecked, setNicknameChecked] = useState(false); // 닉네임 중복 확인 상태
  const [error, setError] = useState(''); // 에러 메시지 상태 추가

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setError(''); // 입력 시 에러 메시지 초기화
    if (e.target.name === 'user_nickname') {
      setNicknameChecked(false); // 닉네임 입력 시 중복 확인 상태 초기화
    }
  };

  const handleNextStep = () => {
    if (step === 1 && !termsAccepted) {
      alert('이용약관에 동의하셔야 합니다.');
      return;
    }
    if (step === 2 && !formData.user_email.includes('@')) {
      setError('올바른 이메일 주소를 입력해주세요.');
      return;
    }
    if (step === 3) {
      if (formData.user_login_pw !== formData.user_login_pw_confirm) {
        setError('비밀번호가 일치하지 않습니다.');
        return;
      }
    }
    setError('');
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      navigate('/main'); // 첫 화면에서 "이전으로" 버튼 클릭 시 메인 페이지로 이동
    }
  };

  const handleNicknameCheck = () => {
    if (!formData.user_nickname) {
      setError('닉네임을 입력해주세요.');
      return;
    }

    axios.post('/api/check-nickname', { user_nickname: formData.user_nickname })
      .then((response) => {
        if (response.data.exists) {
          setError('이미 사용 중인 닉네임입니다.');
          setNicknameChecked(false);
        } else {
          setError('');
          setNicknameChecked(true);
          alert('사용 가능한 닉네임입니다.');
        }
      })
      .catch((error) => {
        console.error(error);
        setError('닉네임 중복 확인 중 오류가 발생했습니다.');
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.user_login_pw !== formData.user_login_pw_confirm) {
      setError('비밀번호가 일치하지 않습니다.');
      return;
    }
    if (step === 4 && !nicknameChecked) {
      setError('닉네임 중복 확인을 해주세요.');
      alert('닉네임 중복 확인을 해주세요.'); // 경고창 추가
      return;
    }
    try {
      await axios.post('/api/register', formData);
      alert('회원가입이 완료되었습니다.');
      navigate('/main'); // 가입 완료 후 /main 페이지로 이동
    } catch (error) {
      console.error(error);
      alert('회원가입에 실패하였습니다.');
    }
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const termsText = `
  <개인정보 처리방침>

  <퀘스트21>은 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 정보주체에게 개인정보의 처리와 보호에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립・공개합니다. 
  
  
  개인정보의 처리목적 
  <퀘스트21>은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. 
  1. 회원 가입 및 관리 
  회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별・인증, 회원자격 유지・관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리 시 법정대리인의 동의 여부 확인, 각종 고지・통지, 고충처리 목적으로 개인정보를 처리합니다. 
  
  2. 재화 또는 서비스 제공 
  본인인증, 포인트 적립의 목적으로 개인정보를 처리합니다. 
  
  
  처리하는 개인정보 항목 
  <퀘스트21>은 서비스 제공을 위해 필요 최소한의 범위에서 개인정보를 수집・이용합니다. 
  
  1. 정보주체의 동의를 받지 않고 처리하는 개인정보 항목 
  <퀘스트21>은 다음의 개인정보 항목을 정보주체의 동의없이 처리하고 있습니다. 
  1) 회원 서비스 운영
   · 법적 근거 : 개인정보 보호법 제15조 제1항 제4호(‘계약 이행’) 
  · 수집・이용 항목 : ID, 비밀번호, 이메일 주소
  · 보유 및 이용기간: 회원 탈퇴 시까지 
  
  2) 서비스 내 포인트 적립
   · 법적 근거 : 개인정보 보호법 제15조 제1항 제4호(‘계약 이행’) 
  · 수집・이용 항목 : ID, 이메일 주소, 최근 접속 일시, 답변 작성 및 제출 내역, 포인트 적립 내역
  · 보유 및 이용기간: 회원 탈퇴 시까지 
  
  14세 미만 아동의 개인정보 처리에 관한 사항 
  1. <퀘스트21>은) 14세 미만 아동의 개인정보를 처리하기 위하여 동의가 필요한 경우에는 해당 아동의 법정대리인으로부터 동의를 받습니다. 
  2. <퀘스트21>은 14세 미만 아동의 개인정보 처리에 관하여 그 법정대리인의 동의를 받을 때에는 아동에게 법정대리인의 성명, 연락처와 같이 최소한의 정보를 요구할 수 있으며, 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를 표시하도록 하고, 그 동의 표시를 확인하였음을 법정대리인의 휴대전화 문자메시지로 알리는 방법으로 그 사실을 확인합니다. 
  
  
  개인정보의 처리 및 보유기간 
  <퀘스트21>은 법령에 따른 개인정보 보유・이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유・이용기간 내에서 개인정보를 처리・보유합니다. 
  각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
  
  1. 홈페이지 회원 가입 및 관리: 홈페이지 탈퇴 시까지 
  2. 재화 또는 서비스 제공 : 재화·서비스 공급완료 및 요금결제・정산 완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지
   1) 「전자상거래 등에서의 소비자 보호에 관한 법률」 제6조에 따른 표시・광고, 
  계약내용 및 이행 등 거래에 관한 기록
   - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년 - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 
  2) 「통신비밀보호법」제15조의2에 따른 통신사실확인자료 보관 - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월 - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 
  
  개인정보의 파기 절차 및 방법 
   1. <퀘스트21>은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. 
   2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 
   ※ 다른 법령에 따라 보존하는 개인정보의 항목과 보존 근거는 “5. 개인정보의 처리 및 보유기간‘ 항목에서 확인 가능 
  3. 개인정보 파기의 절차 및 방법은 다음과 같습니다. 
   1) 파기절차
   <퀘스트21>은 파기 사유가 발생한 개인정보를 선정하고, <퀘스트21>의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. 
   2) 파기방법
   <퀘스트21>은 전자적 파일 형태로 기록・저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록・저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. 
`;

  return (
    <div className={styles.registerPage}>
      <div className={styles.registerContainer}>
        <h1>회원가입</h1>
        <div className={styles.progressBar}>
          <div className={styles.progress} style={{ width: `${(step / 4) * 100}%` }}></div>
        </div>
        <form onSubmit={handleSubmit}>
        {step === 1 && (
        <div>
          <h2>QUEST24의 이용약관에 동의해주세요.</h2>
          <label className={styles.checkboxLabel}>
            <input type="checkbox" checked={termsAccepted} onChange={handleTermsChange} />
            이용약관에 동의합니다.
            <button type="button" onClick={openModal} className={styles.detailsButton}>자세히 보기</button>
          </label>
          <div className={styles.buttonGroup}>
            <button type="button" onClick={handlePreviousStep} className={styles.navigationButton}>
                <img src={BackButtonImage} alt="이전으로" className={styles.buttonImage} />
              </button>

              <button type="button" onClick={handleNextStep} className={styles.navigationButton}>
                <img src={NextButtonImage} alt="다음으로" className={styles.buttonImage} />
              </button>
          </div>
        </div>
      )}
          {step === 2 && (
            <div className={styles.step2container}>
              <h2>이메일을 입력해주세요.</h2>
              <input
                type="email"
                name="user_email"
                placeholder="이메일을 입력해주세요."
                value={formData.user_email}
                onChange={handleChange}
                className={styles.inputFieldEmail}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleNextStep();
                  }
                }}
              />
              {error && (
                <div className={styles.errorMessage}>{error}</div>
              )}
              <button type="button" onClick={handlePreviousStep} className={styles.navigationButton}>
                <img src={BackButtonImage} alt="이전으로" className={styles.buttonImage} />
              </button>
              <button type="button" onClick={handleNextStep} className={styles.navigationButton}>
                <img src={NextButtonImage} alt="다음으로" className={styles.buttonImage} />
              </button>
            </div>
          )}
          {step === 3 && (
            <div className={styles.step3container}>
              <h2>아이디와 비밀번호 입력</h2>
              <input
                type="text"
                name="user_login_id"
                placeholder="로그인 ID"
                value={formData.user_login_id}
                onChange={handleChange}
                className={styles.inputField}
              />
              <input
                type="password"
                name="user_login_pw"
                placeholder="비밀번호"
                value={formData.user_login_pw}
                onChange={handleChange}
                className={styles.inputFieldPw}
              />
              <input
                type="password"
                name="user_login_pw_confirm"
                placeholder="비밀번호 재확인"
                value={formData.user_login_pw_confirm}
                onChange={handleChange}
                className={styles.inputFieldPw}
              />
              {error && (
                <div className={styles.errorMessage}>{error}</div>
              )}
              <div className={styles.buttonGroup}>
              <button type="button" onClick={handlePreviousStep} className={styles.navigationButton}>
                <img src={BackButtonImage} alt="이전으로" className={styles.buttonImage} />
              </button>
              <button type="button" onClick={handleNextStep} className={styles.navigationButton}>
                <img src={NextButtonImage} alt="다음으로" className={styles.buttonImage} />
              </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className={styles.step4container}>
              <h2>닉네임 입력</h2>
              <input
                type="text"
                name="user_nickname"
                placeholder="닉네임"
                value={formData.user_nickname}
                onChange={handleChange}
                className={styles.inputFieldNick}
              />
              <button type="button" onClick={handleNicknameCheck} className={styles.navigationButton}>
                <img src={CheckButtonImage} alt="중복확인" className={styles.buttonImage} />
              </button>
              
              {error && (
                <div className={styles.errorMessage}>{error}</div>
              )}

              
              <div className={styles.buttonGroup}>
                <button type="button" onClick={handlePreviousStep} className={styles.navigationButton}>
                  <img src={BackButtonImage} alt="이전으로" className={styles.buttonImage} />
                </button>

                <button type="submit" className={styles.navigationButton}>
                  <img src={RegisterButtonImage} alt="회원가입 완료" className={styles.buttonImage} />
                </button>
              </div>


              {/* <button type="submit" className={styles.navigationButton}></button> */}
            </div>
          )}
        </form>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className={styles.modal}>
        <h2>약관 내용</h2>
        <p className={styles.modalContent}>{termsText}</p>
        <button onClick={closeModal} className={styles.detailsButton}>닫기</button>
      </Modal>
    </div>
  );
}

export default Register;
